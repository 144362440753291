import { createStore } from 'redux';
import reducer from './reducers.js';
import { loadState, saveState } from './state-storage.js';

export const store = createStore(reducer, loadState());

store.subscribe(() => {
  const newState = store.getState();
  saveState(newState);
});

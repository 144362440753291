import { useEffect } from 'react';
import { patchHistoryApi } from 'single-spa';
import { useVersionNameListener } from '../application-version-name-listener.js';
import { installRouter } from '../router.js';
import { parseSearch } from '../url.js';
import { updatePath } from '../store-actions.js';
import { store } from '../store.js';

try {
  /**
   * This is a holdover from SPA ui
   * We inadvertently created dependencies on SPAs location changed handler. Until these locations are fixed this function must be called
   *
   * Nature of the dependency:
   * - Single spa listened to all changes to the tabs url/location
   * - In its implementation of this listening, it would cause multiple url change events to be fired, which would be caught by our own location watchers
   * - We would then recompute the query/queryV2 state based on the url we saw in the location twice, which caused downstream effects
   * - Notably doing something like store.dispatch(updatePath('/insights?queryParameter=1')) would work, when it should not (the second parameter of updatePath is where the query should be)
   */
  patchHistoryApi();
} catch (e) {
  // patchHistoryApi edits the window object, and throws if it is called more than once, which could occur if this code ends up in multiple chunks
}

let reloadOnNavigate = false;
const onVersionNameChanged = () => {
  reloadOnNavigate = true;
};

/**
 * Sets up watcher for navigation changes which resulted from user input.
 * Namely user clicked on a link, user hit the back button.
 * When this happens we:
 *   - Set the stores path & query variables
 *   - Reload the page if the version of the applications manifest has changed
 */
export const useUserNavigationWatcher = () => {
  useVersionNameListener({ onVersionNameChanged });
  useEffect(() => {
    // Setup the route which watches for clicks which would cause a path change
    // installRouter returns an uninstall fn for cleanup
    return installRouter(
      // When the router sees a path range from a click (or window popstate)
      (location) => {
        if (reloadOnNavigate) {
          window.location.reload();
          reloadOnNavigate = false;
        }

        const { pathname, search } = location;
        const queryV2 = parseSearch(search, { parseArrays: true });
        store.dispatch(updatePath(pathname, queryV2));
      },
      document.documentElement,
    );
  }, []);
};

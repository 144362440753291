import { html } from '~/lib/bn-lit-element';
import { memoize } from 'lodash';
import { units } from './conversions.js';
import { preferences } from './preferences.js';
import { getConverter } from './converter-factory.js';

const _underscoreToCamelCase = (item) =>
  item.replace(/_([a-z])/g, (w) => w[1].toUpperCase());

const unitProperties = memoize((unit) => {
  switch (unit) {
  case 'cubicMetres':
  case 'cubicMeters':
    return {
      displayUnit: 'm³',
      defaultPrecision: 2,
    };
  case 'cubicFeet':
    return {
      displayUnit: 'ft³',
      defaultPrecision: 2,
    };
  case 'poundsPerCubicFoot':
    return {
      displayUnit: 'lbs/ft³',
      defaultPrecision: 1,
    };
  case 'kilograms':
    return {
      displayUnit: 'kg',
      defaultPrecision: 0,
    };
  case 'grams':
    return {
      displayUnit: 'g',
      defaultPrecision: 0,
    };
  case 'kilogramsPerCubicMetre':
  case 'kilogramsPerCubicMeter':
    return {
      displayUnit: 'kg/m³',
      defaultPrecision: 1,
    };
  case 'kilometres':
  case 'kilometers':
    return {
      displayUnit: 'km',
      defaultPrecision: 2,
    };
  case 'meters':
  case 'metres':
    return {
      displayUnit: 'm',
      defaultPrecision: 2,
    };
  case 'miles':
    return {
      displayUnit: 'mi',
      defaultPrecision: 2,
    };
  case 'pounds':
    return {
      displayUnit: 'lbs',
      defaultPrecision: 0,
    };
  case 'ounces':
    return {
      displayUnit: 'oz',
      defaultPrecision: 0,
    };
  case 'inches':
    return {
      displayUnit: 'in',
      defaultPrecision: 2,
    };
  case 'tonne':
  case 'tonnes':
    return {
      displayUnit: 'MT',
      defaultPrecision: 2,
    };
  case 'ton':
  case 'tons':
    return {
      displayUnit: 'T',
      defaultPrecision: 2,
    };
  case 'ratio':
    return {
      displayUnit: '%',
      defaultPrecision: 2,
    };
  default:
    return {
      displayUnit: '',
      defaultPrecision: 2,
    };
  }
});

export function buildMeasurement(measurement, pref) {
  let [metric, system, unit] = pref.split(':').map(_underscoreToCamelCase);
  // handle old dimension preference
  if (measurement === 'dimension' && 'metric' === 'distance') {
    metric = 'dimension';
  }
  const converter = getConverter(measurement, metric, system, unit);
  const defaultPrecision = unitProperties(unit).defaultPrecision;
  const convert = (args, precision = defaultPrecision) => {
    const val = converter.convert(args);
    return val ? Number(val.toFixed(precision)) : val;
  };
  const displayUnit = unitProperties(unit).displayUnit || units[metric][system];
  return {
    convert,
    reverse: converter.reverse,
    metric,
    system,
    unit: displayUnit,
    html: (args = {}) => {
      const val = convert(args);
      const num = isNaN(val) ? '-' : new Intl.NumberFormat().format(val);
      return html`${num} <span class="unit">${displayUnit}</span>`;
    },
  };
}

const defaultConverter = {
  convert: () => {},
  reverse: () => {},
  html: () => html``,
  unit: '',
};

class MeasurementsSingleton {
  constructor() {
    this.getFromPrefs = ({ prefs, name, metricOverride, requireMetric, fallbackPreference }) => {
      if (!prefs) {
        return defaultConverter;
      }
      const metricPreference = metricOverride || prefs[name];
      if (!metricPreference) {
        /*
         * The preferences are loaded from local storage at first, but if this is the first time
         * the user has logged in on their browser, the preferences will be empty. In that case,
         * we'll use the default preferences until the store loads the users preferences from the api.
         */
        return defaultConverter;
      }

      const measurement = buildMeasurement(name, metricPreference);

      // some of our preferences are metric-agnostic and silently do conversions.  This isn't always desired, sometimes
      // you need to know you're getting a weight/volume unit.
      if (requireMetric && measurement.metric !== requireMetric) {
        return buildMeasurement(name, prefs[fallbackPreference || requireMetric]);
      }

      return measurement;
    };
    /**
     * IMPORTANT: Prefer to use the useMeasurement hook instead
     * Since this is static, when preferences are changed, any component using this will not re-render.
     * You may get the defaultConverter at first too if the preferences haven't loaded yet.  If this is called
     * directly, the page would need to refresh to get the correct preference.
     **/
    this.get = (name, { metricOverride, requireMetric, fallbackPreference } = {}) => {
      const prefs = preferences.get('metrics');
      return this.getFromPrefs({ prefs, name, metricOverride, requireMetric, fallbackPreference });
    };
  }

  flushCache() {}
}

export const Measurements = new MeasurementsSingleton();

import * as sirenActions from './siren-actions.js';
import useStoreValues from './use-store-values.js';
import useSirenEntity from './use-siren-entity.js';

export default function useOrganizationDescendantsSearchHref({ limit = 0, organizationTypes = [], baseOrganizationHref, summary = true }) {
  const { rootOrganizationHref } = useStoreValues(['rootOrganizationHref']);
  const { entity: rootOrg } = useSirenEntity({ href: baseOrganizationHref || rootOrganizationHref });
  const { entity: searchEntity } = useSirenEntity({ href: rootOrg?.getLinkByRel('https://api.binsentry.com/rel/search-organizations')?.href });

  let filteredSearchHref;

  if (searchEntity) {
    const orgTypeSet = new Set(organizationTypes);
    if (orgTypeSet.size > 0) {
      const { url: filteredSearchUrl } = sirenActions.parseAction(
        searchEntity.getAction('update-organization-types-filter'),
        {
          organizationTypeName: [...orgTypeSet],
        },
        { appendArrays: true, includeDefaults: false },
      );
      filteredSearchHref = filteredSearchUrl.href;
    }
  }

  const { entity: filteredSearchEntity } = useSirenEntity({ href: filteredSearchHref });

  if (!filteredSearchEntity) {
    return {};
  }

  const { url } = sirenActions.parseAction(
    filteredSearchEntity.getAction('search-organizations'),
    {
      sort: 'name',
      query: '',
      summary,
      limit,
    },{
      includeDefaults: false,
    },
  );
  return { searchHref: url?.href };
}

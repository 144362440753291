import conversions from './conversions.js';

/*
measurements
  binLevel = (volume)|weight|percent
  orderAmount = (volume)|weight
  bulkDensity = (bulk_density)
  dimension = (distance)
  volume = (volume)
  weight = (weight)
  weightSmall = (weight)
measurement preference = metric:system_of_measure:units
  metric = volume|weight|distance|bulk_density|ratio
  system_of_measure = imperial|metric|none
  units =
    ratio - percent
    volume - cubic_metres|cubic_feet
    distance - kilometres|miles
    dimension - metres|inches
    weight - tonnes|tons
    bulk_density - kilograms_per_cubic_metre|pounds_per_cubic_foot
*/

export function getConverter(measurement, metric, system, unit) {
  // these measurements are special because they can convert between metrics, e.g. volumes to weight given a bulkDensity
  if (measurement === 'binLevel' || measurement === 'orderAmount') {
    // e.g. kg to tonnes or m3 to cubic feet.  Maintains the same metric and converts from/to the standard unit (kg/m3)
    const unitConverter = getConverter(metric, metric, system, unit);

    return {
      convert: (params) => {
        const sourceMetric = inferSourceMetric({ ...params, metric });
        return unitConverter.convert(METRIC_CONVERTERS[sourceMetric][metric](params));
      },
      reverse: (params) => {
        // 99% of the time we want to store volume
        const destinationMetric = params.metric || 'volume';
        const asStandardUnitForMetric = unitConverter.reverse(params);
        const { [destinationMetric]: value } = METRIC_CONVERTERS[metric][destinationMetric]({
          ...params,
          [metric]: asStandardUnitForMetric,
        });

        return value;
      },
    };
  }

  const convert = system === 'imperial' ? conversions[metric].mtoi : parseFloat;
  const reverse = system === 'imperial' ? conversions[metric].itom : parseFloat;

  if (measurement === 'weight' || measurement === 'weightSmall') {
    // weight is stored in kgs
    let unitConversion;
    switch (unit) {
    case 'tonnes':
      unitConversion = 1000;
      break;
    case 'shortTons':
      unitConversion = 2000;
      break;
    case 'grams':
      unitConversion = 0.001;
      break;
    case 'ounces':
      unitConversion = 0.0625;
      break;
    default:
      unitConversion = 1;
    }
    return {
      convert: ({ weight }) => convert(weight) / unitConversion,
      reverse: ({ weight }) => reverse(weight) * unitConversion,
    };
  }
  if (measurement === 'volume') {
    return {
      convert: ({ volume }) => convert(volume),
      reverse: ({ volume }) => reverse(volume),
    };
  }
  if (measurement === 'bulkDensity') {
    return {
      convert: ({ bulkDensity }) => convert(bulkDensity),
      reverse: ({ bulkDensity }) => reverse(bulkDensity),
    };
  }
  if (measurement === 'distance') {
    return {
      convert: ({ distance }) => convert(distance),
      reverse: ({ distance }) => reverse(distance),
    };
  }
  if (measurement.startsWith('dimension')) {
    return {
      convert: ({ dimension }) => convert(dimension),
      reverse: ({ dimension }) => reverse(dimension),
    };
  }
  if (measurement === 'ratio') {
    return {
      convert: ({ ratio }) => ratio * 100,
      reverse: ({ ratio }) => ratio / 100,
    };
  }
}

function allNumbers(...nums) {
  return nums.every(num => Number.isFinite(num));
}

// Infers the source metric from supplemental arguments.  This is the grossest part of this code.  Ideally callers would
// be explicit about their source data and not have these conversions be done implicitly, taking that away from
// preferences.
function inferSourceMetric({ metric, bulkDensity, totalVolume, totalWeight, weight, volume, ratio }) {
  if (metric === 'weight') {
    if (allNumbers(weight)) {
      return 'weight';
    }
    if (allNumbers(volume, bulkDensity)) {
      return 'volume';
    }
    if (allNumbers(ratio, totalWeight)) {
      return 'ratio';
    }
    return 'weight';
  }

  if (metric === 'volume') {
    if (allNumbers(volume)) {
      return 'volume';
    }
    if (allNumbers(weight, bulkDensity)) {
      return 'weight';
    }
    if (allNumbers(ratio, totalVolume)) {
      return 'ratio';
    }
    return 'volume';
  }

  if (metric === 'ratio') {
    if (allNumbers(ratio)) {
      return 'ratio';
    }
    if (allNumbers(volume, totalVolume)) {
      return 'volume';
    }
    if (allNumbers(weight, totalWeight)) {
      return 'weight';
    }
    return 'ratio';
  }
}

const METRIC_CONVERTERS = (() => {
  const identityConverter = input => input;
  return {
    volume: {
      volume: identityConverter,
      weight: ({ volume, bulkDensity }) => ({ weight: volume * bulkDensity }),
      ratio: ({ volume, totalVolume }) => ({ ratio: volume / totalVolume }),
    },
    weight: {
      weight: identityConverter,
      volume: ({ weight, bulkDensity }) => ({ volume: weight / bulkDensity }),
      ratio: ({ weight, totalWeight }) => ({ ratio: weight / totalWeight }),
    },
    ratio: {
      ratio: identityConverter,
      volume: ({ ratio, totalVolume }) => ({ volume: ratio * totalVolume }),
      weight: ({ ratio, totalWeight }) => ({ weight: ratio * totalWeight }),
    },
  };
})();

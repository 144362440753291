import SirenParse from 'siren-parser';
import { entityStore } from './entity-store.js';
import { Measurements } from './measurements.jsx';
import { store } from './store.js';

export async function getBinLevelMeasurementOverrides(bin) {
  if (!bin) {
    return undefined;
  }
  if (!store.getState().isProducer) {
    return Measurements.get('binLevel');
  }
  const owner = await entityStore.fetchWait(
    bin.getSubEntity('https://api.binsentry.com/rel/owner').href,
    false,
  );
  const ownerEntity = SirenParse(owner.entity);
  const account = await entityStore.fetchWait(
    ownerEntity.getLink('https://api.binsentry.com/rel/account').href,
    false,
  );
  const {
    binLevelUnitsConfigurableForProducers: configurable,
  } = account.entity.properties.settings || {};
  return configurable ? {} : { metricOverride: 'ratio:none:percent' };
}

import { sirenActions } from '~/utility/BinSentry-ui-utility';

// TODO: Imports should be updated to just use the above as opposed to re-exporting from this file
export const SirenActionMixin = (superClass) => class extends superClass {
  getActionFields(action) {
    return sirenActions.getActionFields(action);
  }

  parseAction(action, fields) {
    return sirenActions.parseAction(action, fields);
  }

  submitAction(action, fields) {
    return sirenActions.submitAction(action, fields);
  }
};

import { store, storeActions } from '~/utility/BinSentry-ui-utility';
import { SirenActionMixin } from '../utility/siren-action-mixin';
import { LitEntity, renderIf, css, html } from '../utility/lit-entity';
import { sharedStyles } from '../styles/shared-styles';
import '~/legacy-lit/js/bins/parts/bin-list';

class OrganizationSearchResults extends SirenActionMixin(LitEntity) {
  constructor() {
    super();
    this.selectedOrgIndex = 0;
  }

  static get properties() {
    return {
      query: { type: String, attribute: false },
      results: { type: Array, attribute: false },
      selectedOrgIndex: { type: Number, attribute: false },
      binsHref: { type: String, attribute: false },
      isProducer: { type: Boolean, attribute: false },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        :host bn-card.org-selected {
          --card-background-color: var(--purple-main);
          --card-border-color: var(--purple-dark);
          --card-shadow-color: var(--purple-dark);
          --card-shadow-hover-color: var(--purple-dark);
        }
        :host {
          display: block;
        }
        bn-icon {
          margin-right: 0.25em;
        }
        p {
          margin-left: 0.5rem;
          margin-top: 0;
        }
        .search-results-container {
          display: flex;
          height: 100%;
        }
        .org {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
        .org-selected .org * {
          color: white;
          --crumb-color: white;
        }
        .org-results {
          width: 40%;
          overflow-y: auto;
        }
        .bin-results {
          display: flex;
          flex-direction: column;
          width: 60%;
          background-color: var(--gray-50);
        }
        .bin-list-container {
          overflow-y: auto;
        }
        .org organization-ancestors-bread-crumbs {
          flex-grow: 1;
          --last-crumb-font-size: 1.1em;
          --last-crumb-font-weight: bold;
        }
        .farm-icon {
          font-size: 2rem;
        }
        .bin-list-title {
          text-align: center;
        }
        .places {
          white-space: nowrap;
          text-align: center;
          margin: 0.5rem;
        }
        .places a:hover {
          background-color: var(--purple-dark);
        }
        .places a {
          color: var(--black-base);
          font-weight: bold;
          padding: 0.5rem 0.75rem;
          border-radius: 3px;
        }
        .places bn-icon {
          margin-right: 0.5em;
        }
        .org-breadcrumbs {
          width: 52.5%;
        }
        @media (max-width: 800px) {
          :host bn-card.org-selected {
            --card-background-color: var(--white-base);
            --card-border-color: var(--white-base);
            --card-shadow-color: var(--white-base);
            --card-shadow-hover-color: var(--purple-light);
          }
          .org-selected .org * {
            color: var(--gray-90);
            --crumb-color: var(--gray-90);
          }
          .org-results {
            width: 100%;
          }
          .bin-results {
            display: none;
          }
          .places {
            display: none;
          }
        }
      `,
    ];
  }

  connectedCallback() {
    // Connect the element to the store.
    this.__storeUnsubscribe = store.subscribe(() =>
      this._stateChanged(store.getState()),
    );
    this._stateChanged(store.getState());
    super.connectedCallback();
  }

  disconnectedCallback() {
    this.__storeUnsubscribe();
    super.disconnectedCallback();
  }

  render() {
    if (!this.href) {
      return html``;
    }
    if (this.loading) {
      return html`<bn-loading></bn-loading>`;
    }
    if (!this.query && (!this.results || !this.results.length)) {
      return html`<p>Enter your search text above to get results.</p>`;
    }
    if (!this.results || !this.results.length) {
      return html`<p>No Results</p>`;
    }
    const selectedOrg = this.results[this.selectedOrgIndex];
    const selectedOrgName = selectedOrg
      ? selectedOrg.properties.name
      : 'Organization';
    const { href } = selectedOrg && selectedOrg.getLinkByRel('self');

    const encodedOrgId = encodeURIComponent(href);
    return html`
      <div class="search-results-container">
        <div class="org-results">
          ${this.results.map((org, i) => this._renderOrg(org, i))}
          <bn-load-more-button
            .href="${this.href}"
            @moreloaded="${(e) => this._parseOrgs(e.detail.entity)}"
          ></bn-load-more-button>
        </div>
        <div class="bin-results">
          <h3 class="bin-list-title">${selectedOrgName} Bins</h3>
          <div class="places">
            ${renderIf(
    !this.isProducer,
    () => html`
                <a
                  href="${`/organizations/list?orgId=${encodedOrgId}`}"
                  @click="${this._select}"
                  >View Account</a
                >
              `,
  )}
            <a
              href="${`/customers?organizationId=${encodedOrgId}`}"
              @click="${this._select}"
              >View Bins</a
            >
          </div>
          <div class="bin-list-container">${this._renderBins()}</div>
        </div>
      </div>
    `;
  }

  _renderBins() {
    return html` <bin-list
      .href="${this.binsHref}"
      @select="${() => this.dispatchEvent(new CustomEvent('select'))}"
    ></bin-list>`;
  }

  _renderOrg(org, i) {
    const { href } = org.getLinkByRel('self');
    return html`
      <bn-card
        class="${this.selectedOrgIndex === i ? 'org-selected' : ''}"
        @click="${() => this._selectOrg(org, i)}"
      >
        <div class="org">
          <bn-icon class="farm-icon" name="fas fa-farm"></bn-icon>
          <organization-ancestors-bread-crumbs
            class="org-breadcrumbs"
            .href="${href}"
          ></organization-ancestors-bread-crumbs>
        </div>
      </bn-card>
    `;
  }

  _select() {
    this.dispatchEvent(new CustomEvent('select'));
  }

  _selectOrg(org, i) {
    if (window.innerWidth <= 800) {
      store.dispatch(
        storeActions.navigate('/bins/list', {
          orgId: org.getLinkByRel('self').href,
        }),
      );
      this.dispatchEvent(new CustomEvent('select'));
      return;
    }
    this.selectedOrgIndex = i;
    this.binsHref =
      this.results[this.selectedOrgIndex] &&
      this.getHref(
        'https://api.binsentry.com/rel/bins',
        this.results[this.selectedOrgIndex],
      );
  }

  _entityChanged(entity) {
    super._entityChanged(entity);
    if (this.entity) {
      const action = this.entity.getActionByName('search-organizations');
      const fields = this.getActionFields(action);
      this.query = fields.get('query');
    }
    this.results = (this.entity && this.entity.entities) || [];
    this.selectedOrgIndex = 0;
    if (this.results.length > 0) {
      this.binsHref = this.getHref(
        'https://api.binsentry.com/rel/bins',
        this.results[this.selectedOrgIndex],
      );
    }
  }

  _stateChanged({ isProducer }) {
    this.isProducer = isProducer;
  }

  _parseOrgs(entity) {
    if (!entity || !entity.entities) {
      return;
    }
    this.results = [...this.results, ...entity.entities];
  }
}

window.customElements.define(
  'organization-search-results',
  OrganizationSearchResults,
);

import { useEffect } from 'react';
import { store } from '../store.js';
import useStoreValues from '../use-store-values.js';
import * as storeActions from '../store-actions.js';
import useSirenEntity from '../use-siren-entity.js';
import { Measurements } from '../measurements.jsx';
import { preferences } from '../preferences.js';

/**
 * Listens for changes to the store.currentUserHref
 * Then initializes/updates preferences & user features
 */
export function useCurrentUserInitializer() {
  const { currentUserHref } = useStoreValues(['currentUserHref']);
  const { entity: currentUserEntity } = useSirenEntity({ href: currentUserHref });
  useEffect(() => {
    if (!currentUserEntity) {
      return;
    }

    const preferenceEntities = currentUserEntity.getSubEntitiesByClass('preferences');
    const preferencesToStore = {};
    preferenceEntities.forEach((preference) => {
      const group = preference.class.filter((cl) => cl !== 'preferences')[0];
      preferencesToStore[group] = preference.properties;
    });

    store.dispatch(
      storeActions.setPreferences({
        ...preferencesToStore,
        binLevelMetric: 'volume',
      }),
    );
    store.dispatch(storeActions.setUserFeatures(currentUserEntity.properties.features));
    preferences.flushCache();
    Measurements.flushCache();
  }, [currentUserEntity]);
}

import { useEffect } from 'react';

const pollIntervalMs = 60 * 5 * 1000;
let pollInterval, currentVersionName, _versionNameChangedListeners = [];

const _pollVersion = async () => {
  let respJson;
  try {
    const resp = await fetch('/manifest.json', { method: 'GET' });
    respJson = await resp.json();
    if (!currentVersionName) {
      currentVersionName = respJson.version_name;
      return;
    }
  } catch (e) {
    // If the network request failed for any reason, do nothing.
    return;
  }

  if (currentVersionName !== respJson.version_name) {
    for (const listener of _versionNameChangedListeners) {
      listener(respJson.version_name);
    }
    currentVersionName = respJson.version_name;
  }
};

export const addVersionNameListener = (onVersionNameChanged) => {
  _versionNameChangedListeners.push(onVersionNameChanged);
  clearInterval(pollInterval);
  pollInterval = setInterval(_pollVersion, pollIntervalMs);
  // Poll once right away to grab the initial version;
  _pollVersion();
};

export const removeVersionNameListener = (onVersionNameChanged) => {
  const index = _versionNameChangedListeners.indexOf(onVersionNameChanged);
  if (index === -1) {
    return false;
  }

  _versionNameChangedListeners.splice(index, 1);
  return true;
};

export const useVersionNameListener = ({ onVersionNameChanged }) => {
  useEffect(() => {
    addVersionNameListener(onVersionNameChanged);
    return () => removeVersionNameListener(onVersionNameChanged);
  }, [onVersionNameChanged]);
};

import { store, storeActions, Measurements } from '~/utility/BinSentry-ui-utility';
import { SirenActionMixin } from '../utility/siren-action-mixin';
import { LitEntity, renderIf, css, html } from '../utility/lit-entity';

import './bin-search-results';
import './organization-search-results';
import './device-search-results';
import './autocomplete-popover';
import { BnModalMixin } from '../common/bn-modal-mixin';

class SearchModal extends BnModalMixin(SirenActionMixin(LitEntity)) {
  constructor() {
    super();
    this.query = '';
    this.noscroll = true;
    this.id = 'search-modal';
    this.preserve = true;
    this.escapeDisabled = false;
    this.selectedTab = 0;
    this.showAdvanced = false;
    this.filterByOpenWorkorder = false;
    this.filterByInstalledDevice = false;
    this.lastUsedGeoFilter = {};
    this.showQRScanner = false;
  }

  static get properties() {
    return {
      expand: { type: Boolean, attribute: false },
      query: { type: String },
      searchBinsHref: { type: String, reflect: true },
      searchOrgsHref: { type: String, reflect: true },
      searchDevicesHref: { type: String, reflect: true },
      foundDeviceHref: { type: String, attribute: false },
      selectedTab: { type: Number, attribute: false },
      binsCount: { type: Number, attribute: false },
      orgsCount: { type: Number, attribute: false },
      showAdvanced: { type: Boolean, attribute: false },
      filterByOpenWorkorder: { type: Boolean, attribute: false },
      filterByInstalledDevice: { type: Boolean, attribute: false },
      geoFilterDist: { type: Number, attribute: false },
      geoFilterLat: { type: Number, attribute: false },
      geoFilterLon: { type: Number, attribute: false },
      lastUsedGeoFilter: { type: Object, attribute: false },
      showQRScanner: { type: Boolean, attribute: false },
      qrError: { type: String, attribute: false },
    };
  }

  static get styles() {
    return [
      css`
        bn-modal#search-modal {
          --modal-min-height: 98%;
          --modal-min-width: 94%;
          --modal-max-width: 94%;
          --modal-body-margin: 0;
          --modal-header-padding: 0 0.75rem 0 0;
          overflow-x: hidden;
        }
        #search-modal qr-scanner {
          --scanner-margin: 0;
        }
        #search-modal .content {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        #search-modal bn-tabs {
          margin: 0;
          background-color: var(--surface);
        }
        #search-modal .results {
          flex-grow: 1;
          padding-top: 1rem;
          overflow-y: auto;
        }
        #search-modal .results-content {
          display: flex;
          height: 100%;
        }
        #search-modal .search-results {
          height: 100%;
        }
        #search-modal .results > div {
          display: none;
        }
        #search-modal .results > div[visible] {
          display: block;
        }
        #search-modal .tab-title {
          display: flex;
        }
        #search-modal .search-results-count {
          color: var(--white-base);
          background-color: var(--purple-main);
          border-radius: 4px;
          padding: 0 0.4rem;
          height: 1.5rem;
          margin-top: 0.35rem;
          line-height: 1.55rem;
          min-width: 1.5rem;
          box-sizing: border-box;
          text-align: center;
          font-size: 0.85rem;
        }
        #search-modal .search-results-count-container {
          display: flex;
        }
        #search-modal .count-selected {
          background-color: var(--purple-dark);
        }
        #search-modal .advanced-search-button {
          color: var(--purple-main);
          text-decoration: none;
          line-height: 2em;
          margin: 0.25rem 0 0.25rem 0.25rem;
          padding: 0 0.5rem;
          border: 1px solid var(--white-base);
          border-radius: 2px;
          display: inline-block;
          cursor: pointer;
          float: right;
          font-size: 1rem;
          font-family: var(--content-font, sans-serif);
          background: var(--white-base);
        }
        #search-modal .advanced-search-button:focus {
          outline-color: var(--purple-main);
        }
        #search-modal .advanced-search-button-icon {
          display: none;
        }
        #search-modal .advanced-search-button:hover {
          border: 1px solid var(--gray-50);
        }
        #search-modal .advanced-search-enabled {
          border: 1px solid var(--gray-50);
          box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        }
        #search-modal .advanced-search-options {
          display: flex;
          flex-wrap: wrap;
          padding: 0 0.5rem 0.5rem 0.5rem;
          background-color: var(--white-base);
        }
        #search-modal .advanced-search-options > label {
          display: none;
        }
        #search-modal .advanced-search-options > label[visible] {
          display: flex;
        }
        #search-modal .advanced-search-option {
          flex-wrap: wrap;
          align-items: center;
          padding: 0.5rem;
          margin: 0.5rem 0.5rem 0 0;
          background-color: var(--purple-lighter);
          border-radius: 5px;
        }
        #search-modal .geo-filter-input {
          font-size: 0.9em;
          line-height: 1.5em;
          padding: 3px;
          width: auto;
          max-width: 7rem;
        }
        @media (max-width: 500px) {
          #search-modal .search-results-count-container {
            display: none;
          }
          #search-modal .advanced-search-button {
            padding: 0.5rem;
          }
          #search-modal .advanced-search-button-large {
            display: none;
          }
          #search-modal .advanced-search-button-icon {
            display: flex;
          }
          #search-modal bn-tabs {
            padding: 0 0.25rem;
          }
        }
      `,
    ];
  }

  renderContents() {
    return html`
      <style>
        ${SearchModal.styles.join(' ')}
      </style>
      <autocomplete-popover
        slot="title"
        .href="${this.searchOrgsHref}"
        @submit="${this._submitAll}"
        @query="${this._queryChange}"
        @popoverToggled="${(e) => (this.escapeDisabled = e.detail.hidden)}"
      ></autocomplete-popover>
      ${this._renderResults()}
    `;
  }

  _getOrgResultsCount() {
    if (this.orgsCount >= 0) {
      return html` <div class="search-results-count-container">
        &nbsp;
        <div
          class="search-results-count ${this.selectedTab === 0
    ? 'count-selected'
    : ''}"
        >
          ${this.orgsCount}
        </div>
      </div>`;
    }
    return html``;
  }

  _getBinResultsCount() {
    if (this.binsCount >= 0) {
      return html` <div class="search-results-count-container">
        &nbsp;
        <div
          class="search-results-count ${this.selectedTab === 1
    ? 'count-selected'
    : ''}"
        >
          ${this.binsCount}
        </div>
      </div>`;
    }
    return html``;
  }

  _renderResults() {
    const measurement = Measurements.get('distance');
    return html`
      ${renderIf(
    this.error,
    html`<div class="banner banner--critical"><p>${this.error}</p></div>`,
  )}

      <div class="content">
        <bn-tabs @select="${this._selectTab}">
          <bn-tab ?active=${this.selectedTab === 0}
            ><div class="tab-title">
              Organizations ${this._getOrgResultsCount()}
            </div></bn-tab
          >
          <bn-tab ?active=${this.selectedTab === 1}
            ><div class="tab-title">
              Bins ${this._getBinResultsCount()}
            </div></bn-tab
          >
          ${renderIf(
    this.searchDevicesHref,
    () => html`
              <bn-tab ?active=${this.selectedTab === 2}>Devices</bn-tab>
            `,
  )}
          ${renderIf(
    this.selectedTab === 0 || this.selectedTab === 1,
    () => html`
              <button
                class="advanced-search-button ${this.showAdvanced
    ? 'advanced-search-enabled'
    : ''}"
                @click="${this._toggleAdvancedOptions}"
              >
                <div class="advanced-search-button-large">Advanced</div>
                <bn-icon
                  class="advanced-search-button-icon"
                  name="fas fa-cog"
                ></bn-icon>
              </button>
            `,
  )}
          ${renderIf(
    (this.selectedTab === 0 || this.selectedTab === 1) &&
              this.showAdvanced,
    () => html`
              <button
                class="advanced-search-button"
                @click="${this._clearFilters}"
              >
                <bn-icon
                  class="advanced-search-button-large"
                  name="fas fa-trash-alt"
                ></bn-icon>
                <bn-icon
                  class="advanced-search-button-icon"
                  name="fas fa-trash-alt"
                ></bn-icon>
              </button>
            `,
  )}
          ${renderIf(
    this.selectedTab === 2,
    () => html`
              <div
                class="advanced-search-button"
                @click="${this._toggleScanner}"
              >
                <div class="advanced-search-button-text">
                  ${this.showQRScanner ? 'Cancel' : 'Scan QR'}
                </div>
                <bn-icon
                  class="advanced-search-button-icon"
                  name="fas fa-qrcode"
                ></bn-icon>
              </div>
            `,
  )}
        </bn-tabs>
        ${renderIf(
    this.showAdvanced &&
            (this.selectedTab === 0 || this.selectedTab === 1),
    () => html`
            <div class="advanced-search-options">
              <label
                class="advanced-search-option"
                ?visible="${this.selectedTab === 1}"
              >
                <input
                  type="checkbox"
                  .checked="${this.filterByInstalledDevice}"
                  @change="${(e) =>
    (this.filterByInstalledDevice = e.target.checked)}"
                />
                <span title="label">Only Show Bins With Device Installed</span>
              </label>
              <label
                class="advanced-search-option"
                ?visible="${this.selectedTab === 1}"
              >
                <input
                  type="checkbox"
                  .checked="${this.filterByOpenWorkorder}"
                  @change="${(e) =>
    (this.filterByOpenWorkorder = e.target.checked)}"
                />
                <span title="label">Only Show Bins With Open Workorder</span>
              </label>
              <label
                class="advanced-search-option"
                ?visible="${this.selectedTab === 0 || this.selectedTab === 1}"
              >
                Show Results Within &nbsp;
                <form
                  @keydown="${(e) => e.key === 'Enter' && this._submitAll(e)}"
                  autocomplete="off"
                >
                  <input
                    class="geo-filter-input"
                    type="number"
                    placeholder="Distance"
                    .value="${measurement.convert({
    distance: this.geoFilterDist,
  }) || ''}"
                    @input="${(e) =>
    (this.geoFilterDist = measurement.reverse({
      distance: e.target.value,
    }))}"
                  />
                  &nbsp;${measurement.unit} of &nbsp;
                  <input
                    class="geo-filter-input"
                    type="number"
                    placeholder="Latitude"
                    .value="${this.geoFilterLat || ''}"
                    @input="${(e) => (this.geoFilterLat = e.target.value)}"
                  />
                  ,&nbsp;
                  <input
                    class="geo-filter-input"
                    type="number"
                    placeholder="Longitude"
                    .value="${this.geoFilterLon || ''}"
                    @input="${(e) => (this.geoFilterLon = e.target.value)}"
                  />
                </form>
              </label>
            </div>
          `,
  )}
        ${renderIf(
    this.showQRScanner && this.selectedTab === 2,
    () => html`
            <div>
              ${renderIf(
    this.qrError,
    () =>
      html`<div class="banner banner--critical">
                    ${this.qrError}
                  </div>`,
  )}
              <qr-scanner @data=${this._parseQr} hide-controls></qr-scanner>
            </div>
          `,
  )}
        <div class="results">
          <div
            class="organizations results-content"
            ?visible="${this.selectedTab === 0}"
          >
            <organization-search-results
              class="search-results"
              .href="${this.searchOrgsHref}"
              @select="${this._onSelect}"
              @update="${this._onOrgsChange}"
            ></organization-search-results>
          </div>

          <div
            class="bins results-content"
            ?visible="${this.selectedTab === 1}"
          >
            <bin-search-results
              class="search-results"
              .href="${this.searchBinsHref}"
              reference-point="${JSON.stringify(this.lastUsedGeoFilter)}"
              @select="${this._onSelect}"
              @expand="${this._onExpand}"
              @update="${this._onBinsChange}"
            ></bin-search-results>
          </div>

          ${renderIf(
    this.searchDevicesHref,
    () => html`
              <div
                class="devices results-content"
                ?visible="${this.selectedTab === 2}"
              >
                <device-search-results
                  class="search-results"
                  .href="${this.foundDeviceHref}"
                  @select="${this._onSelect}"
                  @close="${this.close}"
                ></device-search-results>
                <lit-entity
                  .href="${this.searchDevicesHref}"
                  @update="${this._onDevicesChange}"
                ></lit-entity>
              </div>
            `,
  )}
        </div>
      </div>
    `;
  }

  _selectTab(e) {
    this.selectedTab = e.detail.selected;
    if (this.selectedTab !== 2) {
      this.showQRScanner = false;
      this.qrError = '';
    }
  }

  _toggleScanner() {
    this.showQRScanner = !this.showQRScanner;
    if (!this.showQRScanner) {
      this.qrError = '';
    }
  }

  _clearFilters(e) {
    this.filterByOpenWorkorder = false;
    this.filterByInstalledDevice = false;
    this.geoFilterDist = undefined;
    this.geoFilterLat = undefined;
    this.geoFilterLon = undefined;
    this._submitAll(e);
  }

  _toggleAdvancedOptions(e) {
    this.showAdvanced = !this.showAdvanced;
    if (!this.showAdvanced) {
      this.filterByOpenWorkorder = false;
      this.filterByInstalledDevice = false;
      this.geoFilterDist = undefined;
      this.geoFilterLat = undefined;
      this.geoFilterLon = undefined;
    }
  }

  _onBinsChange(e) {
    const { entity } = e.detail;
    this.binsEntity = entity ? entity : undefined;
    this.binsCount = this.binsEntity && this.binsEntity.properties.totalCount;
    this._switchToTabWithResults();
  }

  _onOrgsChange(e) {
    const { entity } = e.detail;
    this.orgsEntity = entity ? entity : undefined;
    this.orgsCount = this.orgsEntity && this.orgsEntity.properties.totalCount;
    this._switchToTabWithResults();
  }

  _switchToTabWithResults() {
    if (!(this.orgsCount >= 0) || !(this.binsCount >= 0)) {
      return;
    }
    if (this.foundDeviceHref && this.query) {
      this.selectedTab = 2;
    } else if (
      this.orgsCount > 0 &&
      (this.binsCount === 0 || this.selectedTab === 2)
    ) {
      this.selectedTab = 0;
    } else if (
      this.binsCount > 0 &&
      (this.orgsCount === 0 || this.selectedTab === 2)
    ) {
      this.selectedTab = 1;
    }
  }

  _onDevicesChange(e) {
    const { entity } = e.detail;
    this.devicesEntity = entity ? entity : undefined;
  }

  _queryChange(e) {
    const { query } = e.detail;
    this.query = query;
    this.dispatchEvent(
      new CustomEvent('query', { detail: { query: this.query } }),
    );
  }

  _onSelect() {
    // Re-dispatching here as the original event can't bubble through the modals renderRoot
    this.dispatchEvent(
      new CustomEvent('select', { bubbles: true, composed: true }),
    );
    this.close();
  }

  updated(changedProps) {
    if (!changedProps.has('hide')) {
      return;
    }

    // Hide autocomplete popover when search modal hides
    const autocompletePopover = this.renderRoot.querySelector(
      'autocomplete-popover',
    );
    if (!autocompletePopover) {
      return;
    }
    if (this.hide) {
      autocompletePopover.hidePopover = true;
      this.showQRScanner = false;
      this.qrError = '';
    } else {
      autocompletePopover.focus();
    }
  }

  _onClickExpand() {
    this.expand = !this.expand;
  }

  async _submitAll(e) {
    e.preventDefault();
    this.error = '';
    const [binsHref, orgsHref, devicesHref] = await Promise.all([
      this._getActionHref(this.binsEntity, 'search-bins'),
      this._getActionHref(this.orgsEntity, 'search-organizations'),
      this._submit(this.devicesEntity, 'find-device', 'deviceId'),
    ]);
    if (this.searchBinsHref !== binsHref) {
      this.binsCount = undefined;
    }
    if (this.searchOrgsHref !== orgsHref) {
      this.orgsCount = undefined;
    }
    if (this._validateGeoFilter()) {
      this.lastUsedGeoFilter.latitude = this.geoFilterLat;
      this.lastUsedGeoFilter.longitude = this.geoFilterLon;
    } else {
      this.lastUsedGeoFilter = {};
    }
    this.searchBinsHref = binsHref;
    this.searchOrgsHref = orgsHref;
    this.foundDeviceHref = devicesHref;
  }

  _validateGeoFilter() {
    if (
      this.geoFilterLat === undefined ||
      this.geoFilterLat > 90 ||
      this.geoFilterLat < -90
    ) {
      return false;
    }
    if (
      this.geoFilterLon === undefined ||
      this.geoFilterLon > 180 ||
      this.geoFilterLon < -180
    ) {
      return false;
    }
    if (!this.geoFilterDist || !(this.geoFilterDist > 0)) {
      return false;
    }
    return true;
  }

  _parseQr(e) {
    const { data } = e.detail;
    if (!data) {
      return;
    }
    try {
      const url = new URL(data);
      if (!url.hostname.includes('binsentry.com')) {
        this.qrError = 'Unsupported hostname.';
        return;
      }
      if (url.pathname.startsWith('/devices/')) {
        const deviceId = url.searchParams.get('deviceId');
        const serialNumber = deviceId.split('/').pop();
        if (
          this.query === serialNumber &&
          this.qrError === 'Failed to Find Device With That ID'
        ) {
          return;
        }
        const autocompletePopover = this.renderRoot.querySelector(
          'autocomplete-popover',
        );
        if (!autocompletePopover) {
          return;
        }
        this.query = serialNumber;
        autocompletePopover.setQuery(this.query);
        this._submitAll(e);
      }
    } catch {
      this.qrError = 'Could Not Parse QR Code';
    }
  }

  setGeoFilter(e) {
    if (
      !e.detail ||
      e.detail.latitude === undefined ||
      e.detail.longitude === undefined
    ) {
      return;
    }
    const autocompletePopover = this.renderRoot.querySelector(
      'autocomplete-popover',
    );
    if (!autocompletePopover) {
      return;
    }
    autocompletePopover.clear();
    this.filterByOpenWorkorder = false;
    this.filterByInstalledDevice = false;
    this.geoFilterDist = 20;
    this.geoFilterLat = e.detail.latitude;
    this.geoFilterLon = e.detail.longitude;
    this.selectedTab = e.detail.selectedTab || 0;
    this.showAdvanced = true;
    this.query = '';
    this._submitAll(e);
  }

  async _getActionHref(entity, actionName, fieldName = 'query') {
    if (!entity) {
      return;
    }
    const action = entity.getActionByName(actionName);
    if (!action) {
      return;
    }

    this.dispatchEvent(
      new CustomEvent('submit', { detail: { query: this.query } }),
    );
    const fields = this.getActionFields(action);
    fields.set(fieldName, this.query);

    // Apply the default sort value if it is present
    const sortField = action.fields.find((f) => f.name === 'sort');
    if (sortField) {
      // Sorts are implemented as radios, which have an array of values
      fields.set('sort', sortField.value.find((v) => v.checked).value);
    }

    // Apply flag filters to the bin search
    if (actionName === 'search-bins') {
      fields.set('filterDeviceInstalled', this.filterByInstalledDevice);
      fields.set('filterOpenWorkOrder', this.filterByOpenWorkorder);
    }

    // Apply geo filters to the bin and org search
    if (actionName === 'search-bins' || actionName === 'search-organizations') {
      if (this._validateGeoFilter()) {
        fields.set('geoFilterDist', `${this.geoFilterDist}km`);
        fields.set('geoFilterLat', this.geoFilterLat);
        fields.set('geoFilterLon', this.geoFilterLon);
      } else {
        fields.delete('geoFilterDist');
        fields.delete('geoFilterLat');
        fields.delete('geoFilterLon');
      }
    }

    const actionUrl = this.parseAction(action, fields);
    return (actionUrl && actionUrl.url.href) || undefined;
  }

  async _submit(entity, actionName, fieldName = 'query') {
    if (!entity) {
      return;
    }
    const action = entity.getActionByName(actionName);
    if (!action) {
      return;
    }
    try {
      this.loading = true;
      this.dispatchEvent(
        new CustomEvent('submit', { detail: { query: this.query } }),
      );
      const fields = this.getActionFields(action);
      fields.set(fieldName, this.query);
      const { entity, error } = await this.submitAction(action, fields);

      // If a device was found, navigate to its device details page
      if (entity && entity.class.includes('device') && this.query) {
        store.dispatch(
          storeActions.navigate('/devices/show/overview', {
            deviceId: this.getHref('self', entity),
          }),
        );
        this.dispatchEvent(new CustomEvent('close'));
        this.dispatchEvent(
          new CustomEvent('select', { bubbles: true, composed: true }),
        );
        this.qrError = '';
        this.selectedTab = 2;
      }

      if (error) {
        throw new Error(error);
      } else {
        this.dispatchEvent(new CustomEvent('response', { detail: { entity } }));
        return entity.getLinkByRel('self').href;
      }
    } catch (err) {
      if (actionName === 'find-device') {
        this.qrError = 'Failed to Find Device With That ID';
      }
      this.error = err.message;
    } finally {
      this.loading = false;
    }
  }
}

window.customElements.define('search-modal', SearchModal);

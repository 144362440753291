import { BnBreadCrumbs } from '../../common/bn-bread-crumbs';
import { StoreMixin } from '../../utility/store-mixin';

export class OrganizationBreadCrumbs extends StoreMixin(BnBreadCrumbs) {
  constructor() {
    super();
    this.organizationItems = [];
  }

  static get properties() {
    return {
      organizationItems: { type: Array },  // [{organization (required), href, onClick}]
      currentOrgHref: { type: String, attribute: false },
    };
  }

  render() {
    // only render organizations up to currentOrgHref
    const currentOrgIndex = this.organizationItems.findIndex(({ organization }) =>
      (organization.getLink('self') || organization.getLink('via')).href === this.currentOrgHref);
    const itemsToRender = currentOrgIndex === -1 ? this.organizationItems : this.organizationItems.slice(currentOrgIndex);

    this.items = itemsToRender.map(({ organization, href, onClick }) => ({
      name: organization.properties.name,
      href,
      onClick,
    }));
    return super.render();
  }

  _stateChanged ({ currentOrgHref }) {
    this.currentOrgHref = currentOrgHref;
  }
}

window.customElements.define('organization-bread-crumbs', OrganizationBreadCrumbs);

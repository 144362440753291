import SirenParse from 'siren-parser';
import { entityStore } from '~/utility/BinSentry-ui-utility';
import { getActiveWorkOrder } from './work-order-helpers';

export const ActiveWorkOrderWatcherMixin = (superClass) => class extends superClass {
  constructor() {
    super();
    this.activeWorkOrderMap = {};
    this.workOrderHrefs = [];

    this._onWorkOrdersUpdate = this._onWorkOrdersUpdate.bind(this);
  }

  static get properties() {
    return {
      activeWorkOrderMap: { type: Object, attribute: false },
      activeWorkOrder: { type: Boolean, reflect: true, attribute: 'active-work-order' },
    };
  }

  _entityChanged(entity) {
    super._entityChanged(entity);
    if (!this.entity) {
      return;
    }

    this.workOrderHrefs = this.entity.getSubEntitiesByRel('https://api.binsentry.com/rel/work-orders').map((wo) => wo.href);
    const currentKeys = Object.keys(this.activeWorkOrderMap);
    for (const key of currentKeys) {
      if (!this.workOrderHrefs.includes(key)) {
        delete this.activeWorkOrderMap[key];
      }
    }

    this.workOrderHrefs.forEach((href) => {
      entityStore.removeEntityListener(href, this._onWorkOrdersUpdate);
      entityStore.addEntityListener(href, this._onWorkOrdersUpdate);
      entityStore.invalidateHref(href);
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    (this.workOrderHrefs || []).forEach((href) => {
      entityStore.removeEntityListener(href, this._onWorkOrdersUpdate);
    });
  }

  _onWorkOrdersUpdate(e) {
    const { entity } = e;
    if (!entity) {
      return;
    }

    const sirenEntity = SirenParse(entity);
    const key = sirenEntity.getLinkByRel('self').href;
    const newActiveWorkOrder = getActiveWorkOrder(sirenEntity.getSubEntitiesByClass('work-order'));
    if (newActiveWorkOrder) {
      this.activeWorkOrderMap[key] = newActiveWorkOrder;
    }
    this.activeWorkOrder = Object.values(this.activeWorkOrderMap).find((active) => active);
    this.requestUpdate();
  }
};

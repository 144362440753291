import { css, unsafeCSS } from '~/lib/bn-lit-element';

const gray = {
  10: '#FAFAFA',
  20: '#F5F5F5',
  30: '#E5E5E6',
  40: '#D7D7D9', // sky.main
  50: '#AEAEAF', // sky.dark
  60: '#717477', // ink.lightest
  70: '#4B4F54', // ink.lighter
  80: '#303236', // ink.light
  90: '#17181A', // ink.main
  main: 50,
};

export const colors = {
  gray,
  grey: gray,
  blue: {
    10: '#F3F8FB',
    20: '#DBEBF3',
    30: '#ACD0E3',
    40: '#7CB5D2',
    50: '#4D9BC2', // lighter
    60: '#3C89B0', // light
    65: '#7BA2B8',
    70: '#306D8C', // main
    80: '#1D5A73', // dark
    90: '#1A3C4D', // darker
    main: 70,
    text: 90,
  },
  green: {
    10: '#E7EFDF',
    20: '#C6D5B6',
    30: '#AAC192',
    40: '#8DAC6D', // lighter
    50: '#719749', // light
    60: '#587639', // main
    70: '#384B24', // dark
    80: '#28361A', // darker
    90: '#455C2C',
    main: 60,
    text: 80,
  },
  indigo: {
    10: '#F4F6FA',
    15: '#B1C6E4',
    20: '#C9D1E5',
    30: '#9EABD1',
    40: '#7286BD',
    50: '#4D64A2',
    60: '#3C4E7F',
    70: '#303E66',
    80: '#192034',
    main: 60,
    text: 80,
  },
  orange: {
    10: '#FCEDE8',
    20: '#F6CBBB',
    30: '#EF9F81', // lighter
    40: '#E97F57', // light
    50: '#E45F2D', // main
    60: '#B84318', // dark
    70: '#913513', // darker
    80: '#422710',
    main: 50,
    text: 80,
  },
  purple: {
    10: '#F2EDFD',
    20: '#CEB2F6',
    30: '#9C82C3',
    40: '#7F66A5',
    50: '#5C4580',
    60: '#2F2951',
    main: '#5C4580',
    text: '#50495a',

    // Deprecated
    darker: '#2f2957',
    dark: '#5c4580',
    light: '#dac9f4',
    lighter: '#ebe1f2',
  },
  tangerine: {
    10: '#FAD1B0',
    20: '#F7BB88',
    30: '#F5A461',
    40: '#F28D39',
    50: '#EF7510',
    60: '#C25F0D',
    70: '#874209',
    80: '#4B2505',
    main: 40,
    text: 80,
  },
  red: {
    10: '#FCF3F2',
    20: '#F6DAD8',
    30: '#E4AEAA',
    40: '#D6857F', // lighter
    50: '#D3463C', // light
    60: '#BB342A', // main
    70: '#922921', // dark
    80: '#75211A', // darker
    90: '#41120F',
    main: 60,
    text: 90,
  },
  teal: {
    10: '#EAF5F5',
    20: '#C9E5E4', // lighter
    30: '#8BC5C5', // light
    40: '#64B2B1', // main
    50: '#3D9F9E', // dark
    60: '#286867', // darker
    70: '#1F5150',
    80: '#153737',
    main: 40,
    text: 80,
  },
  yellow: {
    10: '#FFFAE1',
    20: '#FFF7D3',
    30: '#FEF1BB',
    40: '#FEE264',
    50: '#F1BC2A',
    60: '#DFA811',
    70: '#BF8C00',
    80: '#845C00',
    main: 50,
    text: 80,
  },
  forest: {
    10: '#EFF5F4',
    20: '#D0E1DD',
    30: '#B1CDC6',
    40: '#73A598',
    50: '#5A8C7E',
    60: '#2F6D5D',
    70: '#224E42',
    80: '#18382F',
    main: 60,
  },
  olive: {
    10: '#F4F5D6',
    20: '#E6E7A0',
    30: '#D2D555',
    40: '#AEB12C',
    50: '#939625',
    60: '#797B1E',
    70: '#5E5F18',
    80: '#434411',
    main: 60,
    text: 80,
  },
};

export const LitColours = css`
  :root,
  * {
    --black-base: #000000;
    --white-base: #ffffff;
    --highlight-color: #3D9F9E10;

    ${unsafeCSS(`
    --fill-level-low: ${colors.red[50]};
    --fill-level-medium: ${colors.tangerine[40]};
    --fill-level-high: ${colors.green[40]};
    `)}
    ${(() => {
    // This produces css vars which follow the schema of
    // --colourName-shadeName: #colour;
    let colourCssVariables = '';
    const colourNames = Object.keys(colors);
    for (const colourName of colourNames) {
      const colourShades = colors[colourName];
      const shadeNames = Object.keys(colourShades);
      for (const shadeName of shadeNames) {
        if (typeof colourShades[shadeName] === 'number') {
          const shade = colourShades[shadeName];
          if (shadeName === 'text') {
            colourCssVariables += `
                --${colourName}-text: ${colourShades[shade]};
              `;
          } else if (shadeName === 'main') {
            colourCssVariables += `
                --${colourName}-main: ${colourShades[shade]};
                --${colourName}-lighter: ${colourShades[shade - 20]};
                --${colourName}-light: ${colourShades[shade - 10]};
                --${colourName}-dark: ${colourShades[shade + 10]};
                --${colourName}-darker: ${colourShades[shade + 20]};
              `;
          }
        } else {
          colourCssVariables += `
            --${colourName}-${shadeName}: ${colourShades[shadeName]};
          `;
        }
      }
    }
    return unsafeCSS(colourCssVariables);
  })()}
`;

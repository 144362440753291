import { LitEntity, html, css } from '../../utility/lit-entity';
import { sharedStyles } from '../../styles/shared-styles';
import '../props/bin-current-report-date';
import './bin-level-metric';

class BinSummary extends LitEntity {
  constructor() {
    super();
    this.precision = 2;
  }

  static get properties() {
    return {
      inline: { type: Boolean },
      row: { type: Boolean },
      precision: { type: Number },
    };
  }

  static get styles() {
    return [sharedStyles, css`
      :host {
        display: inline-block;
      }
      bin-current-report-date {
        font-size: .8em;
        color: var(--bin-current-report-date-color, #999);
        display: block;
      }
      :host([inline]) bin-current-report-date {
        display: inline-block;
      }
      :host([row]) bin-current-report-date {
        display: inline-block;
        float: right;
      }
    `];
  }

  render() {
    return html`
      <bin-level-metric .href="${this.href}" precision="${this.precision}"></bin-level-metric>
      <bin-current-report-date .href="${this.latestLevelHref}"></bin-current-report-date>
    `;
  }

  _entityChanged(entity) {
    super._entityChanged(entity);
    this.latestLevelHref = this.getHref('https://api.binsentry.com/rel/bin-level-latest');
  }
}

window.customElements.define('bin-summary', BinSummary);

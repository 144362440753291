export const LitTraceMixin = (superClass) => class extends superClass {
  static get properties() {
    return {
      debug: { type: Boolean },
    };
  }

  __trace(...args) {
    this.debug && console.info(...args); // eslint-disable-line no-console
  }
};

import { LitEntity, html } from '../../utility/lit-entity';
import { sharedStyles } from '../../styles/shared-styles';
import '../../organizations/props/organization-name';

class BinOwner extends LitEntity {
  static get styles() {
    return sharedStyles;
  }

  render() {
    return html`<organization-name .href="${this.ownerHref}"></organization-name>`;
  }

  _entityChanged(entity) {
    super._entityChanged(entity);
    this.ownerHref = this.getHref('https://api.binsentry.com/rel/owner');
  }
}

window.customElements.define('bin-owner', BinOwner);

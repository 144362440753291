import React from 'react';
import { SvgIcon } from '~/lib/bn-material-ui';

export function MillBinIcon(props) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <g opacity='0.6'>
        <mask id='path-1-inside-1' fill='white'>
          <path fillRule='evenodd' clipRule='evenodd' d='M5 3.57748V21.5714C5 22.1237 5.44772 22.5714 6 22.5714H9.46167C9.17338 22.2248 9 21.7793 9 21.2932V9.4805C9 8.74861 9.39977 8.07517 10.0423 7.72471L13 6.11141V3.57748C13 3.14222 12.7185 2.75695 12.3038 2.62473L9.30376 1.66825C9.10615 1.60525 8.89385 1.60525 8.69624 1.66825L5.69624 2.62473C5.28154 2.75695 5 3.14222 5 3.57748Z'/>
        </mask>
        <path fillRule='evenodd' clipRule='evenodd' d='M5 3.57748V21.5714C5 22.1237 5.44772 22.5714 6 22.5714H9.46167C9.17338 22.2248 9 21.7793 9 21.2932V9.4805C9 8.74861 9.39977 8.07517 10.0423 7.72471L13 6.11141V3.57748C13 3.14222 12.7185 2.75695 12.3038 2.62473L9.30376 1.66825C9.10615 1.60525 8.89385 1.60525 8.69624 1.66825L5.69624 2.62473C5.28154 2.75695 5 3.14222 5 3.57748Z' fill='currentColor'/>
        <path d='M9.46167 22.5714V23.6857H11.8379L10.3183 21.8588L9.46167 22.5714ZM10.0423 7.72471L10.5759 8.70293L10.5759 8.70293L10.0423 7.72471ZM13 6.11141L13.5336 7.08964L14.1143 6.77289V6.11141H13ZM12.3038 2.62473L11.9653 3.68637L11.9653 3.68637L12.3038 2.62473ZM9.30376 1.66825L9.64224 0.60662L9.64224 0.60662L9.30376 1.66825ZM8.69624 1.66825L8.35776 0.60662L8.35776 0.60662L8.69624 1.66825ZM5.69624 2.62473L6.03472 3.68637L6.03472 3.68637L5.69624 2.62473ZM6.11429 21.5714V3.57748H3.88571V21.5714H6.11429ZM6 21.4571C6.06312 21.4571 6.11429 21.5083 6.11429 21.5714H3.88571C3.88571 22.7391 4.83231 23.6857 6 23.6857V21.4571ZM9.46167 21.4571H6V23.6857H9.46167V21.4571ZM10.3183 21.8588C10.1903 21.705 10.1143 21.5094 10.1143 21.2932H7.88571C7.88571 22.0491 8.15642 22.7447 8.605 23.284L10.3183 21.8588ZM10.1143 21.2932V9.4805H7.88571V21.2932H10.1143ZM10.1143 9.4805C10.1143 9.15638 10.2913 8.85814 10.5759 8.70293L9.50872 6.74648C8.50822 7.29221 7.88571 8.34084 7.88571 9.4805H10.1143ZM10.5759 8.70293L13.5336 7.08964L12.4664 5.13319L9.50872 6.74648L10.5759 8.70293ZM11.8857 3.57748V6.11141H14.1143V3.57748H11.8857ZM11.9653 3.68637C11.9179 3.67125 11.8857 3.62722 11.8857 3.57748H14.1143C14.1143 2.65722 13.519 1.84264 12.6422 1.5631L11.9653 3.68637ZM8.96528 2.72989L11.9653 3.68637L12.6422 1.5631L9.64224 0.60662L8.96528 2.72989ZM9.03472 2.72989C9.01213 2.73709 8.98787 2.73709 8.96528 2.72989L9.64224 0.60662C9.22444 0.473415 8.77556 0.473415 8.35776 0.60662L9.03472 2.72989ZM6.03472 3.68637L9.03472 2.72989L8.35776 0.60662L5.35776 1.5631L6.03472 3.68637ZM6.11429 3.57748C6.11429 3.62722 6.08211 3.67125 6.03472 3.68637L5.35776 1.5631C4.48098 1.84264 3.88571 2.65721 3.88571 3.57748H6.11429Z' fill='currentColor' mask='url(#path-1-inside-1)'/>
      </g>
      <path fillRule='evenodd' clipRule='evenodd'
        d='M10 9.90909V21.7218C10 22.8264 10.8954 23.7218 12 23.7218H19C20.1046 23.7218 21 22.8264 21 21.7218V9.90909C21 9.1772 20.6002 8.50377 19.9577 8.1533L16.4577 6.24421C15.8607 5.9186 15.1393 5.9186 14.5423 6.24421L11.0423 8.1533C10.3998 8.50377 10 9.1772 10 9.90909ZM13.5 10.6122C13.5 10.2741 13.1642 9.99999 12.75 9.99999C12.3358 9.99999 12 10.2741 12 10.6122V15.3877C12 15.7259 12.3358 16 12.75 16C13.1642 16 13.5 15.7259 13.5 15.3877V10.6122Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}

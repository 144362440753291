const LBS_PER_FOOT3 = 0.062428; // 1 kg/m³ in lbs/ft³
const FOOT3 = 35.3147; // 1 m³ in ft³
const INCHES = 39.3701; // 1 meter in inches
const POUNDS = 2.20462; // 1 kilogram in pounds
const MILES = 0.621371; // 1 kilometre in miles

// convert between kg/m³ and lbs/ft³
export const bulkDensity = {
  itom: (n) => parseFloat(n) / LBS_PER_FOOT3,
  mtoi: (n) => parseFloat(n) * LBS_PER_FOOT3,
};

// convert between m³ and ft³
export const volume = {
  itom: (n) => parseFloat(n) / FOOT3,
  mtoi: (n) => parseFloat(n) * FOOT3,
};

// convert between meters and inches
export const distance = {
  itom: (n) => parseFloat(n) / MILES,
  mtoi: (n) => parseFloat(n) * MILES,
};

// convert between meters and inches
export const dimension = {
  itom: (n) => parseFloat(n) / INCHES,
  mtoi: (n) => parseFloat(n) * INCHES,
};

// convert between kilograms and pounds
export const weight = {
  itom: (n) => parseFloat(n) / POUNDS,
  mtoi: (n) => parseFloat(n) * POUNDS,
};

export const units = {
  bulkDensity: {
    metric: 'kg/m³',
    imperial: 'lbs/ft³',
  },
  volume: {
    metric: 'm³',
    imperial: 'ft³',
  },
  distance: {
    metric: 'km',
    imperial: 'mi',
  },
  dimension: {
    metric: 'm',
    imperial: 'in',
  },
  weight: {
    metric: 'MT',
    imperial: 'T',
  },
  ratio: {
    none: '%',
  },
};

const defaultBulkDensity = 640.739; // kg/m³, which is 40 lb/ft³

export const kilogramsToOrderAmountPref = (kilograms, toUnit) => {
  if (!toUnit) {
    return undefined;
  }
  let amount = 0;
  switch (toUnit) {
  case 'ft³':
    amount = volume.mtoi(kilograms / defaultBulkDensity);
    break;
  case 'm³':
    amount = kilograms / defaultBulkDensity;
    break;
  case 'MT':
    amount = kilograms / 1000;
    break;
  case 'T':
    amount = weight.mtoi(kilograms) / 2000;
    break;
  case 'kg':
    amount = kilograms;
    break;
  case 'lbs':
    amount = weight.mtoi(kilograms);
    break;
  case 'g':
    amount = kilograms * 1000;
    break;
  case 'oz':
    amount = weight.mtoi(kilograms) * 16;
    break;
  default:
    throw new Error(`kilogramsToOrderAmountPref | Unknown toUnit: ${toUnit}`);
  }
  const precision = ['kg', 'lbs'].includes(toUnit) ? 0 : 2;
  return Number(amount.toFixed(precision));
};

/*
 * returns kilograms, assume 40 lbs/ft^3 bulk density
 */
export const orderAmountPrefToKilograms = (quantity, unit) => {
  if (!unit) {
    return undefined;
  }
  if (unit === 'cubic_feet') {
    return volume.itom(quantity) * defaultBulkDensity;
  }
  if (unit === 'cubic_meter') {
    return quantity * defaultBulkDensity;
  }
  if (unit === 'metric-ton') {
    return quantity * 1000;
  }
  if (unit === 'short-ton') {
    return weight.itom(quantity * 2000);
  }
  if (unit === 'kilogram') {
    return quantity;
  }
  if (unit === 'pound') {
    return weight.itom(quantity);
  }
  throw new Error(`orderAmountPrefToKilograms | Unknown unit: ${unit}`);
};

export default {
  bulkDensity,
  volume,
  distance,
  dimension,
  weight,
  units,
  kilogramsToOrderAmountPref,
  orderAmountPrefToKilograms,
};

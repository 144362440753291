import { parseSearch } from './url.js';

const deepLinkPathBlacklist = ['/login-redirect', '/login', '/logged-out'];

const load = (type, key) => {
  const storage = window[`${type}Storage`];
  if (!storage) {
    return undefined;
  }
  const val = storage.getItem(key);
  return val ? JSON.parse(val) : undefined;
};

const save = function (type, key, value) {
  const storage = window[`${type}Storage`];
  if (!storage) {
    return;
  }
  if (value === undefined) {
    storage.removeItem(key);
  } else {
    storage.setItem(key, JSON.stringify(value));
  }
};

export const loadState = () => ({
  currentOrgHref: load('local', 'currentOrgHref'),
  preferences: load('local', 'preferences'),
  userFeatures: load('local', 'userFeatures'),
  isProducer: load('local', 'isProducer'),
});

export const saveState = (state) => {
  // local storage
  save('local', 'currentOrgHref', state.currentOrgHref);
  save('local', 'preferences', state.preferences);
  save('local', 'userFeatures', state.userFeatures);
  save('local', 'isProducer', state.isProducer);
};

export const clearState = () => {
  try {
    save('local', 'currentOrgHref');
    save('local', 'preferences');
    save('local', 'userFeatures');
    save('local', 'isProducer');
  } catch (err) {
    /* couldn't clear storage */
  }
};

export const rememberDeepLink = () => {
  try {
    if (deepLinkPathBlacklist.includes(window.location.pathname)) {
      return;
    }
    save('session', 'deepLinkUrl', window.location.href);
    save(
      'session',
      'deepLinkExpiry',
      new Date(Date.now() + 10 * 60 * 1000).toISOString(),
    );
  } catch (err) {
    /* deep linking is a nice-to-have */
  }
};

export const popDeepLink = () => {
  try {
    const deepLinkUrl = load('session', 'deepLinkUrl');
    const deepLinkExpiry = load('session', 'deepLinkExpiry') || '0';

    // deep links are one-time use
    save('session', 'deepLinkUrl');
    save('session', 'deepLinkExpiry');

    if (deepLinkUrl && new Date().toISOString() <= deepLinkExpiry) {
      const url = new URL(deepLinkUrl);
      return {
        path: url.pathname,
        query: parseSearch(url.search),
        queryV2: parseSearch(url.search, { parseArrays: true }),
      };
    }
  } catch (err) {
    /* deep linking is a nice-to-have */
  }

  return null;
};

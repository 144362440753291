import moment from 'moment';
import { LitEntity, html } from '../../utility/lit-entity';
import { sharedStyles } from '../../styles/shared-styles';
import '../../common/bn-placeholder';
import '../../common/bn-icon';

class BinEmptyDate extends LitEntity {
  static get styles() {
    return sharedStyles;
  }

  render() {
    if (this.loading) {
      return html`<bn-icon name="fas fa-spinner fa-pulse"></bn-icon>`;
    }

    if (!this.entity || !this.entity.properties) {
      return html`Not enough data`;
    }

    const { properties } = this.entity;
    if (properties.emptyAt) {
      return html`${moment(properties.emptyAt).format('MMM D, h a')}`;
    }

    if (properties.volume && properties.volume.forecast && properties.volume.forecast.length > 0) {
      return html`14+ Days`;
    }

    return html`Not enough data`;
  }
}

window.customElements.define('bin-empty-date', BinEmptyDate);

import React from 'react';
import { SvgIcon } from '~/lib/bn-material-ui';

export function InsightsIcon(props) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.81818 19C3.31818 19 2.89 18.8271 2.53364 18.4812C2.17727 18.1353 1.9994 17.72 2 17.2353C2 16.75 2.17818 16.3344 2.53455 15.9885C2.89091 15.6426 3.31879 15.47 3.81818 15.4706H4.05727C4.12515 15.4706 4.19697 15.4853 4.27273 15.5147L8.40909 11.5C8.37879 11.4265 8.36364 11.3565 8.36364 11.29V11.0588C8.36364 10.5735 8.54182 10.1579 8.89818 9.81206C9.25455 9.46618 9.68242 9.29353 10.1818 9.29412C10.6818 9.29412 11.11 9.46706 11.4664 9.81294C11.8227 10.1588 12.0006 10.5741 12 11.0588C12 11.0882 11.9848 11.2353 11.9545 11.5L14.2727 13.75C14.3485 13.7206 14.4206 13.7059 14.4891 13.7059H14.9664C15.0342 13.7059 15.1061 13.7206 15.1818 13.75L18.4091 10.6176C18.3788 10.5441 18.3636 10.4741 18.3636 10.4076V10.1765C18.3636 9.69118 18.5418 9.27559 18.8982 8.92971C19.2545 8.58382 19.6824 8.41118 20.1818 8.41176C20.6818 8.41176 21.11 8.58471 21.4664 8.93059C21.8227 9.27647 22.0006 9.69176 22 10.1765C22 10.6618 21.8218 11.0774 21.4655 11.4232C21.1091 11.7691 20.6812 11.9418 20.1818 11.9412H19.9427C19.8748 11.9412 19.803 11.9265 19.7273 11.8971L16.5 15.0294C16.5303 15.1029 16.5455 15.1729 16.5455 15.2394V15.4706C16.5455 15.9559 16.3673 16.3715 16.0109 16.7174C15.6545 17.0632 15.2267 17.2359 14.7273 17.2353C14.2273 17.2353 13.7991 17.0624 13.4427 16.7165C13.0864 16.3706 12.9085 15.9553 12.9091 15.4706V15.2385C12.9091 15.1726 12.9242 15.1029 12.9545 15.0294L10.6364 12.7794C10.5606 12.8088 10.4885 12.8235 10.42 12.8235H10.1818C10.1515 12.8235 10 12.8088 9.72727 12.7794L5.59091 16.7941C5.62121 16.8676 5.63636 16.9376 5.63636 17.0041V17.2353C5.63636 17.7206 5.45818 18.1362 5.10182 18.4821C4.74546 18.8279 4.31758 19.0006 3.81818 19ZM4.72727 10.1544L4.15909 8.96323L2.93182 8.41176L4.15909 7.86029L4.72727 6.66912L5.29546 7.86029L6.52273 8.41176L5.29546 8.96323L4.72727 10.1544ZM14.7273 9.29412L13.8636 7.48529L12 6.64706L13.8636 5.80882L14.7273 4L15.5909 5.80882L17.4545 6.64706L15.5909 7.48529L14.7273 9.29412Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}

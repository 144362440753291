import _ from 'lodash';
/**
 * Parses the given query string into a key:value object.
 *
 * We have places that expect a simple object for query params (e.g. that's what is passed to our redux nav actions)
 * but it should be noted that this isn't really general purpose since it assumes that keys are not duplicated.  That's
 * why URLSearchParams gives back an iterator.
 *
 * @param search the search string
 * @param options options for parsing the search string
 *                If parseArrays is false, maps key to the last value bound to that key in the search string
 * @return {*} an object mapping keys.
 */
export function parseSearch(search, options = { parseArrays: false }) {
  return search
    .slice(1)
    .split('&')
    .reduce((acc, item) => {
      const [key, val = ''] = item.split('=');
      if (key) {
        if (options.parseArrays) {
          if (!acc.hasOwnProperty([decodeURIComponent(key)])) {
            acc[decodeURIComponent(key)] = decodeURIComponent(val);
          } else {
            acc[decodeURIComponent(key)] = [..._.castArray(acc[decodeURIComponent(key)]), decodeURIComponent(val)];
          }
        } else {
          acc[decodeURIComponent(key)] = decodeURIComponent(val);
        }
      }
      return acc;
    }, {});
}

import { html, ifDefined } from '~/lib/bn-lit-element';
import { entityStore } from '~/utility/BinSentry-ui-utility';
import { LitTraceMixin } from './lit-trace-mixin';
import '../common/bn-loading';

export const LitEntityMixin = (superClass) => class extends LitTraceMixin(superClass) {
  constructor() {
    super();
    this.__onEntityUpdate = this.__onEntityUpdate.bind(this);
    this.loadingDisplayWaitMS = 100;
  }

  static get properties() {
    return {
      href: { type: String },
      loadingDisplayStyle: { type: String, attribute: 'loading-display-style' },
      loadingDisplayWaitMS: { type: String, attribute: 'loading-display-wait-ms' },
      entity: { type: Object, attribute: false },
      refreshing: { type: Boolean, attribute: false },
      loading: { type: Boolean, attribute: false },
    };
  }

  connectedCallback() {
    super.connectedCallback && super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback && super.disconnectedCallback();
    this.href && entityStore.removeEntityListener(this.href, this.__onEntityUpdate);
  }

  get isReady() {
    return !this.loading && this.entity;
  }

  ready() {
  }

  _checkReady() {
    if (this.isReady) {
      this.dispatchEvent(new CustomEvent('ready'));
      this.ready();
    }
  }

  updated() {
    this._checkReady();
  }

  /* Override this to render once the entity has been loaded */
  renderEntity() {
    return html``;
  }

  render() {
    if (this.loading) {
      return this.renderLoading();
    }

    if (!this.entity) {
      return this.renderNoEntity();
    }

    return this.renderEntity();
  }

  renderLoading() {
    return html`
      <bn-loading
        display-style="${ifDefined(this.loadingDisplayStyle)}"
        render-wait-ms="${ifDefined(this.loadingDisplayWaitMS)}"
      ></bn-loading>
    `;
  }

  renderNoEntity() {
    return html``;
  }

  shouldUpdate(props) {
    if (!props.has('href')) {
      return super.shouldUpdate && super.shouldUpdate();
    }
    // remove existing entity listener
    const prevHref = props.get('href');
    prevHref && entityStore.removeEntityListener(prevHref, this.__onEntityUpdate);
    // add new entity listener
    this.href && entityStore.addEntityListener(this.href, this.__onEntityUpdate);
    // props has the previous value; clear the entity if href was set, and isn't anymore.
    if (this.entity && prevHref && !this.href) {
      this._entityChanged(undefined);
    }

    this._fetch();
    return true;
  }

  __onEntityUpdate({ entity, error, status }) {
    this.loading = false;
    this.refreshing = status === 'stale-refresh';
    this._entityChanged(entity, error);
  }

  _entityChanged(entity, error) {
    this.entity = entity;
  }

  async _fetch(bypassCache = false) {
    if (!this.href) {
      return;
    }
    this.loading = true;

    const { entity, error, status } = await entityStore.getAndFetch(this.href, bypassCache);
    if (status !== 'fetching') {
      // Allows class/mixin to override _entityChanged
      this.__onEntityUpdate({ entity, error, status });
    }
  }
};

import { store } from './store.js';

let _preferencesCache = {};
const _getPreferences = () => {
  if (Object.keys(_preferencesCache).length === 0) {
    /*
     * The preferences are loaded from local storage at first, but if this is the first time
     * the user has logged in on their browser, the preferences will be empty.
     */
    const prefs = store.getState().preferences;
    _preferencesCache = prefs ? prefs : {};
  }

  return _preferencesCache;
};

class Factory {
  flushCache() {
    _preferencesCache = {};
  }

  get(groupName) {
    const preferences = _getPreferences();

    if (!groupName) {
      return preferences;
    }

    return preferences[groupName];
  }
}

export const preferences = new Factory();

import { LitElement, html, css } from '~/lib/bn-lit-element';
import './bn-icon';

class BnLoading extends LitElement {
  constructor() {
    super();
    this.loadingText = 'Loading...';
  }

  static get properties() {
    return {
      displayStyle: { type: String, attribute: 'display-style' },
      renderWaitMS: { type: Number, attribute: 'render-wait-ms' },
      loadingText: { type: String, attribute: 'loading-text' },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    if (!this.renderWaitMS) {
      return;
    }

    this.renderTimeout = setTimeout(() => {
      clearTimeout(this.renderTimeout);
      this.renderTimeout = undefined;
      this.requestUpdate();
    }, this.renderWaitMS);
  }

  disconnectedCallback() {
    clearTimeout(this.renderTimeout);
    this.renderTimeout = undefined;
  }

  static get styles() {
    return css`
      :host {
        display: block;
        text-align: center;
        font-size: 1em;
      }
      :host([display-style="inline"]),
      :host([display-style="icon-only"]) {
        display: inline;
      }
      .block {
        padding: 8px;
      }
      .block bn-icon {
        font-size: 1.5em;
      }
    `;
  }

  render() {
    if (this.renderWaitMS && this.renderTimeout) {
      return html``;
    }

    if (this.displayStyle === 'icon-only') {
      return html`<bn-icon name="far fa-spinner fa-pulse"></bn-icon>`;
    }

    if (this.displayStyle === 'inline') {
      return html`<bn-icon name="far fa-spinner fa-pulse"></bn-icon> ${this.loadingText}`;
    }

    return html`
      <div class="block">
        <slot><p>${this.loadingText}</p></slot>
        <bn-icon name="far fa-spinner fa-pulse">⚙</bn-icon>
      </div>
    `;
  }
}

window.customElements.define('bn-loading', BnLoading);

import { useCallback, useMemo } from 'react';
import { entityStore } from './entity-store.js';
import SirenParse from 'siren-parser';
import { useSyncExternalStore } from 'use-sync-external-store/shim/index.js';
import _ from 'lodash';

/**
 * A React hook to subscribe to entitystore updates for a given href. This hook does NOT trigger a load of the entity!
 * @param {string} href The href of the entity to use.
 * @return {{refreshing: boolean, href: string, loading: boolean, entity}}
 */
export function useSirenEntityUpdates({ href }) {
  const subscribe = useCallback((cb) => entityStore.addEntityListener(href, cb), [href]);
  const getSnapshot = useMemo(() => createGetSnapshot(href), [href]);
  const { loading, refreshing, rawEntity } = useSyncExternalStore(subscribe, getSnapshot);
  const entity = useMemo(() => rawEntity ? SirenParse(rawEntity) : undefined, [rawEntity]);
  return { href, loading, refreshing, entity };
}

function createGetSnapshot(href) {
  let snapshot = undefined;
  return () => {
    const newSnapshot = getUnstableSnapshot(href);
    snapshot = _.isEqual(snapshot, newSnapshot) ? snapshot : newSnapshot;
    return snapshot;
  };
}

function getUnstableSnapshot(href) {
  const { status, entity: rawEntity } = href ? entityStore.get(href) : {};
  const loading = href ? ['stale-refresh', 'fetching', 'not-stored'].includes(status) : false;
  const refreshing = href ? ['stale-refresh'].includes(status) : false;
  return { href, loading, refreshing, rawEntity };
}

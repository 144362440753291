import { LitEntity, html, css } from '../../utility/lit-entity';

class OrganizationName extends LitEntity {
  static get styles() {
    return css`
      :host {
        display: inline;
        overflow: hidden;
      }
    `;
  }

  render() {
    if (!this.entity) {
      return html`<bn-placeholder inline></bn-placeholder>`;
    }

    return html`
      <span title="${this.entity.properties.name}">
        ${this.entity.properties.name}
      </span>
    `;
  }
}

window.customElements.define('organization-name', OrganizationName);

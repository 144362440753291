import moment from 'moment';
import { LitEntity, html } from '../../utility/lit-entity';
import { sharedStyles } from '../../styles/shared-styles';
import '../../common/bn-placeholder';

class BinCurrentReportDate extends LitEntity {
  static get styles() {
    return sharedStyles;
  }

  render() {
    const date = this.entity?.properties?.publishedAt;
    const formattedDate = date ? moment(date).format('MMM D, h:mm a') : 'unknown';

    return html`${formattedDate}`;
  }
}

window.customElements.define('bin-current-report-date', BinCurrentReportDate);

import useAccount from './use-account.js';

export default function useAccountFeatures({ organizationId }) {
  const { account, loading } = useAccount({ organizationId });

  return {
    features: account?.properties.features || {},
    loading,
  };
}

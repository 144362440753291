import ReactDOM from 'react-dom';
import { store } from './store.js';

// never cleaned up, here for the life of the app
store.subscribe(() => {
  ReactDOM.unstable_batchedUpdates(() => {
    rebroadcaster.dispatchEvent(new CustomEvent('store-updated'));
  });
});

class Rebroadcaster extends EventTarget {
}

const rebroadcaster = new Rebroadcaster();

export function subscribe(cb) {
  const uniqueCb = () => cb();
  rebroadcaster.addEventListener('store-updated', uniqueCb);
  return () => rebroadcaster.removeEventListener('store-updated', uniqueCb);
}

export function getSnapshot() {
  return store.getState();
}


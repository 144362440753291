const activeStates = ['requested', 'assigned', 'ready', 'hold'];

export const getActiveWorkOrder = (workOrders = []) => {
  const activeWorkOrders = workOrders.filter((entity) => (
    activeStates.includes(entity.properties.state) && (!entity.properties.isDataReliable || entity.properties.isColdWeatherIssue)
  ));

  return activeWorkOrders[0];
};

export const hasActiveWorkOrder = (workOrders = []) => {
  return !!getActiveWorkOrder(workOrders);
};

import { LitEntity, html } from '../../utility/lit-entity';

class BinSmoothingEnabled extends LitEntity {
  static get properties() {
    return {
      binLevelProperties: { type: Object, attribute: false },
    };
  }

  render() {
    const binLevelPropertiesHref = this.getHref('https://api.binsentry.com/rel/bin-level-bin-properties');
    return html`
      <lit-entity .href="${binLevelPropertiesHref}" @update="${this._binLevelPropertiesChanged}"/>
    `;
  }

  update(changed) {
    super.update(changed);
    this._checkSmoothingEnabledChanged(changed);
  }

  _checkSmoothingEnabledChanged(changed) {
    if (!this.binLevelProperties) {
      return;
    }

    // only fire event if something changed
    if (!(changed.has('binLevelProperties'))) {
      return;
    }

    // Bins may opt out of smoothing, but it is enabled by default
    const smoothingEnabled = this.binLevelProperties?.smoothingModel !== 'none' || true;

    this.dispatchEvent(new CustomEvent('smoothingEnabled', { detail: { smoothingEnabled } }));
  }

  _binLevelPropertiesChanged(e) {
    const { entity } = e.detail || {};
    this.binLevelProperties = entity?.properties;
  }
}

window.customElements.define('bin-smoothing-enabled', BinSmoothingEnabled);

export function getAccountHref(organizationEntity) {
  if (!organizationEntity) {
    return;
  }

  const organizationTypeName = organizationEntity.getSubEntity(
    'https://api.binsentry.com/rel/organization-type',
  ).properties.name;

  return organizationTypeName === 'account'
    ? organizationEntity.getLink('self').href
    : organizationEntity.getLink('https://api.binsentry.com/rel/account')?.href;
}

import { LitElement, html } from '~/lib/bn-lit-element';

import { LitEntityMixin } from './lit-entity-mixin';
import { LitSirenEntityMixin } from './lit-siren-entity-mixin';

export { SirenActionMixin } from './siren-action-mixin';
export { html, css, svg, nothing } from '~/lib/bn-lit-element';

export const renderIf = (show, content) => {
  if (!show) {
    return html``;
  }
  if (typeof content === 'function') {
    return content();
  }
  return content;
};

export class LitEntity extends LitSirenEntityMixin(LitEntityMixin(LitElement)) {}

// When using <lit-entity> directly, nothing should ever be rendered
class RawLitEntity extends LitEntity {
  render() {
    return html``;
  }
}

window.customElements.define('lit-entity', RawLitEntity);

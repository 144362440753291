import { LitEntity, html, renderIf, css } from '../../utility/lit-entity';
import { sharedStyles } from '../../styles/shared-styles';
import '../../common/bn-placeholder';
import '../../organizations/parts/organization-ancestors-bread-crumbs';

/**
 * @deprecated Unless working in legacy-lit, use the React component `BinPathName` from `~/bins/bin-path-name`.
 */
class BinPathName extends LitEntity {
  constructor() {
    super();
    this.ownerHref = '';
    this.linkPath = '';
    this.ancestorMinIndex = 1;
    this.queryParam = 'orgId';
  }

  static get properties() {
    return {
      singleline: { type: Boolean },
      stopAtType: { type: String, attribute: 'stop-at-type' },
      linkPath: { type: String },
      ancestorMinIndex: { type: Number },
      binLinkPath: { type: String, attribute: 'bin-link-path' },
      ownerHref: { type: Object, attribute: false },
      queryParam: { type: String, attribute: 'query-param' },
    };
  }

  static get styles() {
    return [sharedStyles, css`
      .crumb {
        text-decoration: none;
        color: var(--crumb-color, var(--gray-90));
      }
      .bin-link {
        text-decoration: var(--bin-link-decoration, underline);
        color: var(--bin-link-color, var(--crumb-color, var(--gray-90)));
      }
      .bin-link:hover {
        text-decoration: underline;
      }
    `];
  }

  render() {
    if (!this.ownerHref) {
      return html`<bn-placeholder></bn-placeholder>`;
    }

    return html`
      <organization-ancestors-bread-crumbs
        .href="${this.ownerHref}"
        .stopAtType="${this.stopAtType}"
        .linkPath="${this.linkPath}"
        .queryParam="${this.queryParam}"
        .ancestorMinIndex="${this.ancestorMinIndex}"
        ?singleline="${this.singleline}"
        @ready="${this._onAncestorsReady}"
      >
        ${this.renderName()}
      </organization-ancestors-bread-crumbs>
    `;
  }

  renderName() {
    const binName = html`<bin-name href="${this.href}" @ready="${this._onBinNameReady}"></bin-name>`;

    if (!this.binLinkPath) {
      return binName;
    }

    const url = new URL(this.binLinkPath, window.location.origin);
    url.searchParams.set('binId', this.href);
    const href = url.toString();

    return html`<a class="crumb bin-link" .href="${href}">${binName}</a>`;
  }

  get isReady() {
    return super.isReady && this.ancestorsReady && this.binNameReady;
  }

  _onAncestorsReady() {
    this.ancestorsReady = true;
    this._checkReady();
  }

  _onBinNameReady() {
    this.binNameReady = true;
    this._checkReady();
  }

  _entityChanged(entity) {
    super._entityChanged(entity);
    this.ownerHref = this.getHref('https://api.binsentry.com/rel/owner');
  }
}

window.customElements.define('bin-path-name', BinPathName);

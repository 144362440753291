import _ from 'lodash';
import {
  CHANGE_ORG,
  SET_CURRENT_USER,
  SET_ORGANIZATION_SETTINGS,
  SET_ORGANIZATION_TYPE,
  SET_PREFERENCES,
  SET_PRIMARY_ORG,
  SET_ROOT_ORGANIZATION,
  UPDATE_PATH,
  UPDATE_AUTHENTICATION,
  AUTHENTICATION_CLEARED,
  CLEAR,
  SET_MENU_HIDDEN,
  SET_USER_FEATURES,
  SET_PERSONAS,
} from './store-actions.js';

// reducers
export default function (state, action) {
  switch (action.type) {
  case CHANGE_ORG:
    return { ...state, currentOrgHref: action.href };
  case SET_CURRENT_USER:
    return { ...state, currentUserHref: action.href };
  case SET_MENU_HIDDEN:
    return { ...state, menuHidden: action.menuHidden };
  case SET_ORGANIZATION_SETTINGS:
    return { ...state, organizationSettings: action.organizationSettings };
  case SET_ORGANIZATION_TYPE:
    return { ...state, organizationType: action.organizationType };
  case SET_PERSONAS:
    return {
      ...state,
      isProducer: _.isEqual(action.personas, ['producer']),
      personas: action.personas,
    };
  case SET_PREFERENCES:
    return { ...state, preferences: action.preferences };
  case SET_USER_FEATURES:
    return { ...state, userFeatures: action.userFeatures };
  case SET_PRIMARY_ORG:
    return { ...state, primaryOrgHref: action.href };
  case SET_ROOT_ORGANIZATION:
    return { ...state, rootOrganizationHref: action.href };
  case UPDATE_AUTHENTICATION:
    return { ...state, token: action.token, currentSubject: action.subject };
  case AUTHENTICATION_CLEARED:
    return { ...state, clearAuthentication: false };
  case UPDATE_PATH:
    return { ...state, path: action.path, query: action.query, queryV2: action.queryV2 };
  case CLEAR:
    return {
      ...state,
      currentUserHref: undefined,
      currentOrgHref: undefined,
      primaryOrgHref: undefined,
      rootOrganizationHref: undefined,
      isProducer: undefined,
      token: undefined,
      currentSubject: undefined,
      clearAuthentication: true,
    };
  default:
    // Don't error on built in redux actions
    if (!action.type.includes('@@redux')) {
      console.error(
        'Action used with no corresponding reducer, resulting in no effects',
        action,
      );
    }

    return state;
  }
}

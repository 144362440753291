import { useSirenEntity, useStore } from '~/utility/BinSentry-ui-utility';

export function useCurrentMillBinsGroup() {
  const { currentOrgHref } = useStore();
  const { entity: currentOrgEntity, loading: currentOrgLoading } = useSirenEntity({ href: currentOrgHref });
  const currentOrgIsMillBinGroup = currentOrgEntity && orgIsMillBinsGroup(currentOrgEntity);

  const { entity: organizationChildrenEntity, loading: orgChildrenLoading } = useSirenEntity({
    href: currentOrgIsMillBinGroup ? undefined : currentOrgEntity?.getSubEntityByRel('https://api.binsentry.com/rel/organization-children')?.href,
  });

  const millBinsGroup = currentOrgIsMillBinGroup ? currentOrgEntity : organizationChildrenEntity?.getSubEntities('item').find(orgIsMillBinsGroup);
  const loading = currentOrgLoading || orgChildrenLoading;

  return { millBinsGroup, loading };
}

function orgIsMillBinsGroup(org) {
  return org.getSubEntityByRel('https://api.binsentry.com/rel/organization-type')?.properties.name === 'mill-bins-group';
}

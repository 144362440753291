import { LitElement, html, css } from '~/lib/bn-lit-element';
import { sharedStyles } from '../styles/shared-styles';

class BnCard extends LitElement {
  static get properties() {
    return {
      href: { type: String },
    };
  }

  static get styles() {
    return [sharedStyles, css`
      :host {
        display: block;
        margin: var(--root-margin);
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-color: var(--card-background-color, var(--surface, white));
        box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px;
        border: 2px solid var(--card-border-color, transparent);
        user-select: text;
        text-decoration: none;
        border-radius: 10px;
        transition: .2s box-shadow, .2s transform, .2s border-color, .2s filter;
      }
      a:hover {
        cursor: pointer;
        box-shadow: 0 6px 6px rgb(0 0 0 / 10%);
        transform: translateY(-3px);
        border-color: var(--card-shadow-hover-color, var(--purple-light));
      }
      .bn-card-body {
        margin: var(--root-margin);
        display: block;
        height: calc(100% - (2 * var(--root-margin)));
      }
    `];
  }

  render() {
    if (this.href) {
      return html`<a href="${this.href}">${this._renderCardBody()}</a>`;
    } else {
      return html`<a>${this._renderCardBody()}</a>`;
    }
  }

  _renderCardBody() {
    return html`
      <slot name="header"></slot>
      <div class="bn-card-body">
        <slot></slot>
      </div>
      <slot name="footer"></slot>
    `;
  }
}

window.customElements.define('bn-card', BnCard);

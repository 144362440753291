import { css } from '~/lib/bn-lit-element';

export const formStyles = css`
.form-field {
  margin: 0 0 1em;
}

.form-field + .form-actions {
  margin-top: 1em;
}

label {
  color: var(--gray-80);
  font-weight: normal;
  font-size: .9em;
  font-family: var(--content-font);
}

label[for] {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.property-group + .property-group,
.field-group + .field-group {
  margin-top: 1.5em;
}

input[type=text],
input[type=search],
input[type=tel],
input[type=email],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=password],
input[type=number],
select,
textarea {
  background-color: var(--white-base);
  border: 1px solid var(--gray-60);
  box-sizing: border-box;
  color: var(--gray-90);
  font-family: var(--content-font);
  font-size: 1em;
  line-height: 1.5em;
  padding: 3px;
  width: 100%;
}

input[type=text]:disabled,
input[type=search]:disabled,
input[type=tel]:disabled,
input[type=email]:disabled,
input[type=date]:disabled,
input[type=datetime]:disabled,
input[type=datetime-local]:disabled,
input[type=password]:disabled,
input[type=number]:disabled,
select:disabled,
textarea:disabled {
  background-color: var(--gray-20);
  border-color: var(--gray-40);
  color: var(--gray-70);
}

.input-wrapper {
  display: flex;
  width: 100%;
  align-items: baseline;
}

.input-wrapper input {
  flex-grow: 1;
}

.suffix {
  color: black;
  margin-left: .5em;
  padding: 4px;
}

.button {
  background-color: var(--gray-40);
  border-radius: 2px;
  border: 1px solid var(--gray-50);
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.1);
  color: var(--gray-90);
  cursor: pointer;
  display: inline-block;
  font-family: var(--content-font);
  font-size: 1em;
  padding: 8px 16px;
  transition: 0.2s color, 0.2s border-color, 0.2s background-color;
  text-decoration: none;
}
.button[full-width] {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.button--primary {
  background-color: var(--teal-60);
  border-color: var(--teal-60);
  color: var(--white-base);
  text-shadow: unset;
}
.button--primary:hover:enabled {
  background-color: var(--teal-dark);
}

.button--secondary {
  background-color: unset;
  border-color: var(--teal-60);
  color: var(--teal-60);
  text-shadow: unset;
}
.button--secondary:hover:enabled {
  background-color: var(--teal-10);
}

.button--tertiary {
  background-color: unset;
  border: none;
  box-shadow: unset;
  color: var(--teal-60);
  text-decoration: underline;
  text-shadow: unset;
}
.button--tertiary:hover {
  background-color: var(--teal-10);
}

.button--secondary {
  background-color: var(--white-base);
  border-color: var(--teal-60);
  color: var(--teal-60);
}

.button--secondary:hover {
  background-color: var(--teal-10);
}

.button--destructive {
  background-color: var(--red-60);
  border-color: var(--red-60);
  color: var(--white-base);
  text-shadow: unset;
}
.button--destructive:hover:enabled {
  background-color: var(--red-50);
}

.button--destructive-diminished {
  background-color: var(--white-base);
  border-color: var(--red-60);
  color: var(--red-60);
  text-shadow: unset;
}
.button--destructive-diminished:hover:enabled {
  background-color: var(--red-10);
  border-color: var(--red-70);
  color: var(--red-70);
}

.button--positive {
  background-color: var(--green-main);
  border-color: var(--green-dark);
  color: var(--white-base);
  text-shadow: 0 0 2px var(--green-darker);
}

.button--text,
.button--plain {
  padding: 0;
  margin: 0;
  text-decoration: underline;
  border: none;
  background: unset;
  box-shadow: unset;
}
.button--text:focus,
.button--text:hover,
.button--plain:focus,
.button--plain:hover {
  background: var(--highlight-color);
}

.button.ghost {
  text-shadow: none;
  box-shadow: none;
  background: transparent;
}

button::-moz-focus-inner {
  border: 0;
}

.button:disabled,
.button.disabled {
  background-color: var(--gray-20);
  border-color: var(--gray-40);
  color: var(--gray-70);
  cursor: default;
  text-shadow: none;
}

.list-actions {
  padding: 8px;
  text-align: center;
}

.list-actions .button {
  margin: 4px;
}
`;

export default formStyles.toString();

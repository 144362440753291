import _ from 'lodash';
import { useCallback } from 'react';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/shim/with-selector.js';
import { getSnapshot, subscribe } from './react-store-helper.js';

export default function useStoreValues(keys) {
  const selector = useCallback(snapshot => _.pick(snapshot, keys), [keys]);
  return useSyncExternalStoreWithSelector(
    subscribe,
    getSnapshot,
    null, // getServerSnapshot
    selector,
    (oldSelection, newSelection) => _.isEqual(oldSelection, newSelection),
  );
}

import { LitEntity, html } from '../../utility/lit-entity';
import { sharedStyles } from '../../styles/shared-styles';
import '../../common/bn-placeholder';

class BinName extends LitEntity {
  static get styles() {
    return sharedStyles;
  }

  render() {
    if (!this.entity) {
      return html`<bn-placeholder></bn-placeholder>`;
    }
    return html`
      <span title="${this.entity.properties.name}">${this.entity.properties.name}</span>
    `;
  }
}

window.customElements.define('bin-name', BinName);

import { LitElement, html, css } from '~/lib/bn-lit-element';
import { sharedStyles } from '../styles/shared-styles';

class BnPlaceholder extends LitElement {
  static get properties() {
    return {
      inline: { type: Boolean },
    };
  }

  static get styles() {
    return [sharedStyles, css`
      :host {
        display: block;
        height: .8em;
        margin: .2em 0;
        background-color: var(--purple-lighter);
      }
      :host([inline]) {
        display: inline-block;
        min-width: var(--placeholder-min-width, 5em);
      }
    `];
  }

  render() {
    return html`&nbsp;`;
  }
}

window.customElements.define('bn-placeholder', BnPlaceholder);

import {
  InMemoryWebStorage,
  Log as OidcLog,
  User,
  UserManager,
  WebStorageStateStore,
} from 'oidc-client';
import { captureException } from '@sentry/browser';
import { store } from './store.js';
import {
  navigate,
  clear,
  authenticationCleared,
  updateAuthentication,
} from './store-actions.js';

OidcLog.logger = console;
OidcLog.level = OidcLog.WARN;

const postLogoutRedirectUri = `${window.location.origin}/logged-out`;

let setUserManager;
const getUserManager = (() => {
  return new Promise((res) => {
    setUserManager = res;
  });
})();

// this specialized store allows each tab to maintain their own access token, while still having a central store
// to read the initial user from.  Every time a browser tab sets the user in storage, it'll update the central (local) store,
// but we'll only read from the local store on init.
class PropagatingWebStorageStateStore extends WebStorageStateStore {
  constructor(authority, userPoolWebClientId) {
    super({
      store: new InMemoryWebStorage(),
    });

    // one-time import of users
    this._localStorageStateStore = new WebStorageStateStore({
      store: localStorage,
    });

    const userKey = (this._userKey = `user:${authority}:${userPoolWebClientId}`);
    const userStorageKey = `${this._prefix}${userKey}`;
    const userStorageValue = localStorage.getItem(userStorageKey);
    const user = userStorageValue && User.fromStorageString(userStorageValue);

    if (user) {
      this.set(userKey, user.toStorageString()).then(/* ignored */);
    }
  }

  set(key, value) {
    if (key === this._userKey) {
      if (value) {
        // erase the access token from local storage.  Each tab always gets its own access tokens, but share the
        // refresh token
        try {
          const storedUser = JSON.parse(value);
          storedUser && delete storedUser.access_token;
          storedUser.expires_at = 1; // truthy but always in the past
          const storedValue = JSON.stringify(storedUser);
          this._localStorageStateStore
            .set(key, storedValue)
            .then(/* ignored */);
        } catch (e) {
          // couldn't sanitize.  All this is means is that the refresh token won't be propagated
          captureException(e);
        }
      }
    }
    return super.set(key, value);
  }

  remove(key) {
    if (key === this._userKey) {
      this._localStorageStateStore.remove(key).then(/* ignored */);
    }
    return super.remove(key);
  }
}

let logOutIfAfter = new Date().toISOString();

async function initInitialUser(userManager) {
  const storedUser = await userManager.getUser();
  if (!storedUser) {
    return null;
  }

  if (!storedUser.expired) {
    return storedUser;
  }

  try {
    return await userManager.signinSilent();
  } catch (e) {
    // could just be the refresh token has expired
    return null;
  }
}

// we use storage events to log out of all tabs
function listenForLogouts(userManager) {
  window.addEventListener('storage', async (e) => {
    if (e.key === 'lastLogOut' && e.newValue > logOutIfAfter) {
      logOutIfAfter = e.newValue;
      await userManager.removeUser();
      await userManager.clearStaleState();
      store.dispatch(clear());
      store.dispatch(navigate('/logged-out'));
    }
  });
}

// store is global and reducers shouldn't have side effects.  We publish clearAuthentication on the clear action
// to instruct us to clear out the userManager state
function subscribeToClearActions(userManager) {
  let clearingState = false;
  store.subscribe(async () => {
    const state = store.getState();
    if (!clearingState && state.clearAuthentication) {
      clearingState = true;
      try {
        await userManager.clearStaleState();
        await userManager.removeUser();
      } finally {
        clearingState = false;
        store.dispatch(authenticationCleared());
      }
    }
  });
}

// bind redux store and user manager so that token and subject are available in the redux store
async function bindStoreToUserManager(userManager) {
  const onUser = (user) => {
    store.dispatch(
      updateAuthentication({
        token: user.access_token,
        subject: user.profile && user.profile.sub,
      }),
    );
  };

  const onNoUser = () => {
    store.dispatch(updateAuthentication({ token: null, subject: null }));
  };

  userManager.events.addUserLoaded(onUser);
  userManager.events.addUserUnloaded(onNoUser);
  const initialUser = await initInitialUser(userManager);

  if (initialUser && !initialUser.expired) {
    onUser(initialUser);
  } else {
    onNoUser();
  }
}

export const init = async ({ userPoolId, userPoolWebClientId, domain }) => {
  const authority = `https://cognito-idp.us-east-1.amazonaws.com/${userPoolId}/`;
  const stateStore = new PropagatingWebStorageStateStore(
    authority,
    userPoolWebClientId,
  );

  const userManager = new UserManager({
    authority,
    client_id: userPoolWebClientId,
    redirect_uri: `${window.location.origin}/login-redirect`,
    post_logout_redirect_uri: postLogoutRedirectUri,
    response_type: 'code',
    scope: ['email', 'profile', 'openid'].join(' '),
    metadataSeed: {
      // gross but cognito doesn't publish metadata for this
      end_session_endpoint: `https://${domain}/logout`,
    },
    staleStateAge: 60 * 60 * 24 * 31,
    automaticSilentRenew: true,
    userStore: stateStore,

    // This skew only affects whether the client accepts the token.  The server will still validate on its own when
    // we pass the access token back up.
    //
    // The issue we're running into is that some user clients have wildly inaccurate system times.  If a very liberal
    // tolerance doesn't fix the issue, the next step would probably be to override clockService and adjust the actual
    // clock values that the client uses by fetching the time from the server.
    clockSkew: 65 * 60,
  });

  // Monkey patching this in to be stored for logout
  userManager.userPoolWebClientId = userPoolWebClientId;
  userManager.clearStaleState().then(/* nothing */);

  // just housekeeping, gets rid of unconsumed state events from unfinished redirects
  listenForLogouts(userManager);
  subscribeToClearActions(userManager);
  await bindStoreToUserManager(userManager);
  setUserManager(userManager);
};

export async function authenticatedUser() {
  const userManager = await getUserManager;
  const user = await userManager.getUser();

  if (!user || user.expired) {
    return null;
  }

  return user;
}

export async function currentUser() {
  const userManager = await getUserManager;
  return await userManager.getUser();
}

export async function logout() {
  const userManager = await getUserManager;
  store.dispatch(clear());

  // makes all of the other tabs redirect to /logged-out, but not this one as ours will go through the proper redirect
  logOutIfAfter = new Date().toISOString();
  localStorage.setItem('lastLogOut', logOutIfAfter);

  await userManager.clearStaleState();

  // signout redirect will remove user
  await userManager.signoutRedirect({
    // this seems to be a bit underspecified by oidc so we have to do some work.  We might find it's better to just
    // ignore userManager for this and call this endpoint directly
    extraQueryParams: {
      client_id: userManager.userPoolWebClientId,
      logout_uri: postLogoutRedirectUri,
    },
  });
}

export async function signoutRedirectCallback() {
  const userManager = await getUserManager;
  return userManager.signoutRedirectCallback(...arguments);
}

export async function signinRedirectCallback() {
  const userManager = await getUserManager;
  return userManager.signinRedirectCallback(...arguments);
}

export async function signinRedirect() {
  const userManager = await getUserManager;
  return userManager.signinRedirect(...arguments);
}

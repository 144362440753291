import SirenParse from 'siren-parser';
import { entityStore } from '~/utility/BinSentry-ui-utility';

export const LitSirenEntityMixin = (superClass) => class extends superClass {
  static get properties() {
    return {
      entity: { type: Object },
    };
  }

  _entityChanged(entity) {
    try {
      this.entity = entity ? SirenParse(entity) : undefined;
    } catch (err) {
      console.error('There was an error parsing the entity', err); // eslint-disable-line no-console
      this.entity = undefined;
    }
    const detail = { entity: this.entity };
    this.dispatchEvent(new CustomEvent('update', { detail }));
  }

  async fetchSiren(href, bypassCache = true) {
    if (!href) {
      return;
    }
    const { entity } = await entityStore.fetchWait(href, bypassCache);
    return SirenParse(entity);
  }

  getHref(...params) {
    const [rel] = params;
    const entity = params.length > 1 ? params[1] : this.entity;
    if (!rel || !entity) {
      return undefined;
    }
    const link = entity.getSubEntityByRel(rel) || entity.getLinkByRel(rel);
    return link && link.href ? link.href : undefined;
  }

  async fetchRaw(href, bypassCache) {
    const headers = new Headers();
    if (bypassCache) {
      headers.append('pragma', 'no-cache');
      headers.append('cache-control', 'no-cache');
    }
    const token = await entityStore.getToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    const response = await fetch(href, { method: 'GET', headers });
    if (!response.ok) {
      return null;
    }

    return response.body;
  }

  getProperty(name, defaultValue, entity = this.entity) {
    if (!name || !entity) {
      return defaultValue;
    }
    let { properties: value } = entity;
    if (!value) {
      return defaultValue;
    }
    const keys = name.split('.');
    for (const key of keys) {
      value = value[key];
      if (value === undefined) {
        return defaultValue;
      }
    }
    return value;
  }
};

import { LitElement, html, css } from '~/lib/bn-lit-element';

class BnIcon extends LitElement {
  static get styles() {
    return css`
      :host {
        display: inline-block;
      }
    `;
  }

  static get properties() {
    return {
      name: { type: String },
    };
  }

  render() {
    return html`
      <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.15.1/css/all.css" integrity="sha384-9ZfPnbegQSumzaE7mks2IYgHoayLtuto3AS6ieArECeaR8nCfliJVuLh/GaQ1gyM" crossorigin="anonymous">
      <i class="${this.name}"></i>
    `;
  }
}

window.customElements.define('bn-icon', BnIcon);

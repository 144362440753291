import { SirenActionMixin } from '../utility/siren-action-mixin';
import { LitEntity, renderIf, css, html } from '../utility/lit-entity';
import { sharedStyles } from '../styles/shared-styles';

class AutocompletePopover extends SirenActionMixin(LitEntity) {
  constructor() {
    super();
    this.query = '';
    this.typedQuery = '';
    this.suggestions = [];
    this.selectedSuggestion = -1;
    this.hidePopover = true;
  }

  static get properties() {
    return {
      query: { type: String, attribute: false },
      hidePopover: { type: Boolean, attribute: false },
      typedQuery: { type: String, attribute: false },
      suggestions: { type: Array, attribute: false },
      selectedSuggestion: { type: Number, attribute: false },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('keyup', this._keyup.bind(this));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('keyup', this._keyup);
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        input[name="search-box"]:focus,
        input[name="search-box"] {
          outline: none;
          border: none;
          font-weight: bold;
        }
        form {
          padding: 0.75rem;
        }
        .autocomplete-popover-backdrop {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 11;
        }
        .autocomplete-popover {
          display: flex;
          flex-direction: column;
          position: absolute;
          width: 100%;
          z-index: 11;
          background-color: var(--surface);
          border-top: 1px solid var(--gray-50);
          border-bottom: 1px solid var(--gray-50);
          box-shadow: 0px 15px 12.5px -12.5px rgba(0, 0, 0, 0.2);
        }
        .autocomplete-result {
          padding: 0.5rem 0 0.5rem 0.75rem;
          margin: 0;
          font-family: var(--content-font);
          font-size: 1.1rem;
          text-align: left;
          outline: none;
          background-color: var(--surface);
          border: none;
        }
        .autocomplete-selected {
          background-color: var(--gray-20);
        }
        .autocomplete-result:hover {
          background-color: var(--gray-20);
          cursor: pointer;
        }
      `,
    ];
  }

  render() {
    return html` <form
        @submit="${(e) => this._submit(e, this.query)}"
        autocomplete="off"
      >
        <input
          type="text"
          name="search-box"
          placeholder="Search"
          .value="${this.query}"
          @input="${this._queryChange}"
          @keydown="${this._queryChange}"
          @change="${this._queryChange}"
        />
      </form>
      ${renderIf(
    !this.hidePopover && this.suggestions && this.suggestions.length > 0,
    html` <div
            class="autocomplete-popover-backdrop"
            @mousedown="${() => (this.hidePopover = true)}}"
          ></div>
          <div class="autocomplete-popover">
            ${this.suggestions.map((suggestion, i) => {
    return html` <button
                @click="${(e) => this._submit(e, suggestion.name)}"
                class="autocomplete-result ${this.selectedSuggestion === i
    ? 'autocomplete-selected'
    : ''}"
              >
                ${suggestion.name}
              </button>`;
  })}
          </div>`,
  )}`;
  }

  focus() {
    const input = this.renderRoot.querySelector('input[name="search-box"]');
    if (input) {
      input.focus();
      input.setSelectionRange(0, input.value.length);
    }
  }

  _keyup(e) {
    if (e.key === 'Escape' && !this.hidePopover) {
      this.hidePopover = true;
    }
  }

  updated(changedProperties) {
    if (changedProperties.has('hidePopover')) {
      this.dispatchEvent(
        new CustomEvent('popoverToggled', {
          detail: { hidden: !this.hidePopover },
        }),
      );
    }
  }

  async _queryChange(e) {
    if (!e.key && this.query !== e.target.value) {
      // Update query text
      this.query = e.target.value;
      this.typedQuery = e.target.value;
      this.dispatchEvent(
        new CustomEvent('query', { detail: { query: this.query } }),
      );

      // Fill in autocomplete suggestions
      this.hidePopover = false;
      this.selectedSuggestion = -1;
      const action = this.entity.getActionByName('search-autocomplete');
      const fields = this.getActionFields(action);
      fields.set('query', this.query);
      const { entity } = await this.submitAction(action, fields);
      if (!entity) {
        return;
      }
      this.suggestions = entity.entities.map((result) => result.properties);
    } else if (e.key && e.key === 'ArrowUp') {
      e.preventDefault();
      this._incrementSelectedSuggestion(-1);
    } else if (e.key && e.key === 'ArrowDown') {
      e.preventDefault();
      this._incrementSelectedSuggestion(1);
    }
    if (
      this.query === '' ||
      (this.suggestions && this.suggestions.length === 0)
    ) {
      this.hidePopover = true;
    }
  }

  _incrementSelectedSuggestion(increment) {
    this.selectedSuggestion = Math.min(
      Math.max(this.selectedSuggestion + increment, -1),
      this.suggestions.length - 1,
    );
    if (this.selectedSuggestion === -1) {
      this.query = this.typedQuery;
    } else {
      this.query = this.suggestions[this.selectedSuggestion]
        ? this.suggestions[this.selectedSuggestion].name
        : this.query;
    }
    this.dispatchEvent(
      new CustomEvent('query', { detail: { query: this.query } }),
    );
  }

  _submit(e, queryText) {
    e.preventDefault();
    this.hidePopover = true;
    this.query = queryText;
    this.dispatchEvent(
      new CustomEvent('query', { detail: { query: this.query } }),
    );
    this.dispatchEvent(new CustomEvent('submit'));
  }

  clear() {
    this.query = '';
    this.typedQuery = '';
    this.suggestions = [];
    this.selectedSuggestion = -1;
    this.hidePopover = true;
  }

  setQuery(queryText) {
    if (!queryText) {
      return;
    }
    this.clear();
    this.query = queryText;
    this.typedQuery = queryText;
  }
}

window.customElements.define('autocomplete-popover', AutocompletePopover);

import _ from 'lodash';
import { clearState } from './state-storage.js';

// actions
export const AUTHENTICATION_CLEARED = 'AUTHENTICATION_CLEARED';
export const CHANGE_ORG = 'CHANGE_ORG';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_MENU_HIDDEN = 'SET_MENU_HIDDEN';
export const SET_ORGANIZATION_SETTINGS = 'SET_ORGANIZATION_SETTINGS';
export const SET_ORGANIZATION_TYPE = 'SET_ORGANIZATION_TYPE';
export const SET_PERSONAS = 'SET_PERSONAS';
export const SET_PREFERENCES = 'SET_PREFERENCES';
export const SET_USER_FEATURES = 'SET_USER_FEATURES';
export const SET_PRIMARY_ORG = 'SET_PRIMARY_ORG';
export const SET_ROOT_ORGANIZATION = 'SET_ROOT_ORGANIZATION';
export const UPDATE_AUTHENTICATION = 'UPDATE_AUTHENTICATION';
export const UPDATE_PATH = 'UPDATE_PATH';
export const CLEAR = 'CLEAR';

export const changeOrg = (href) => ({ type: CHANGE_ORG, href });
export const setCurrentUser = (href) => ({ type: SET_CURRENT_USER, href });
export const setOrganizationSettings = (organizationSettings) => ({
  type: SET_ORGANIZATION_SETTINGS,
  organizationSettings,
});
export const setOrganizationType = (organizationType) => ({
  type: SET_ORGANIZATION_TYPE,
  organizationType,
});
export const setMenuHidden = (menuHidden) => {
  return {
    type: SET_MENU_HIDDEN,
    menuHidden,
  };
};
export const setPreferences = (preferences) => ({
  type: SET_PREFERENCES,
  preferences,
});
export const setPersonas = (personas) => ({ type: SET_PERSONAS, personas });
export const setUserFeatures = (userFeatures) => ({
  type: SET_USER_FEATURES,
  userFeatures,
});
export const setPrimaryOrg = (href) => ({ type: SET_PRIMARY_ORG, href });
export const setRootOrganization = (href) => ({ type: SET_ROOT_ORGANIZATION, href });
export const updatePath = (path, queryV2 = {}) => {
  const query = { ...queryV2 };
  for (const key in Object.keys(query)) {
    if (Array.isArray(query[key])) {
      if (query[key].length) {
        query[key] = _.last(query[key]);
      } else {
        delete query[key];
      }
    }
  }
  return {
    type: UPDATE_PATH,
    path,
    query,
    queryV2,
  };
};
export const updateAuthentication = ({ token, subject }) => ({
  type: UPDATE_AUTHENTICATION,
  token,
  subject,
});
export const authenticationCleared = () => ({ type: AUTHENTICATION_CLEARED });

export function buildUrl(path, query) {
  let url = path;
  if (query) {
    const params = new URLSearchParams();
    for (const key of Object.keys(query)) {
      if (Array.isArray(query[key])) {
        query[key].forEach(value => params.append(key, value));
      } else {
        params.set(key, query[key]);
      }
    }
    const search = params.toString();
    if (search) {
      url = `${url}?${search}`;
    }
  }
  return url;
}

export const navigate = (path, query) => {
  let url = buildUrl(path, query);
  if (`${window.location.pathname}${window.location.search}` !== url) {
    window.history.pushState({}, '', url);
    window.scrollTo(0, 0);
  }
  return updatePath(path, query);
};

export const replaceLocation = (path, query) => {
  let url = buildUrl(path, query);
  window.history.replaceState({}, '', url);
  return updatePath(path, query);
};

export const clear = () => {
  clearState();
  return { type: CLEAR };
};

export const login = () => {
  clearState();
  return navigate('/login');
};

import { html } from '../../utility/lit-entity';
import { OrganizationBreadCrumbs } from './organization-bread-crumbs';

class OrganizationAncestorsBreadCrumbs extends OrganizationBreadCrumbs {
  constructor() {
    super();
    this.ancestors = [];
    this.linkPath = '';
    this.ancestorMinIndex = 1;
    this.queryParam = 'orgId';
  }

  static get properties() {
    return {
      linkPath: { type: String },
      ancestorMinIndex: { type: Number },
      stopAtType: { type: String, attribute: 'stop-at-type' },
      ancestorsHref: { type: Object, attribute: false },
      ancestors: { type: Array, attribute: false },
      queryParam: { type: String, attribute: 'query-param' },
    };
  }

  get isReady() {
    return !this.loading && this.entity && this.ancestors.length;
  }

  render() {
    return html`
      ${this._renderAncestors()}
      <lit-entity .href="${this.ancestorsHref}" @update="${this._onUpdateAncestors}"></lit-entity>
    `;
  }

  _renderAncestors() {
    if (!this.entity) {
      return super.render();
    }
    let ancestors = [...this.ancestors.slice(this.ancestorMinIndex), this.entity];
    if (this.stopAtType) {
      const index = ancestors.findIndex((org) => {
        const type = org.getSubEntityByRel('https://api.binsentry.com/rel/organization-type');
        return type && type.properties.name === this.stopAtType;
      });
      if (index !== -1) {
        ancestors = ancestors.slice(index + 1);
      }
    }
    this.organizationItems = ancestors.map((ancestor) => ({
      organization: ancestor,
      href: this._ancestorHref(ancestor),
    }));
    return super.render();
  }

  _ancestorHref(org) {
    if (this.linkPath) {
      const url = new URL(this.linkPath, window.location.origin);
      if (this.queryParam) {
        url.searchParams.set(this.queryParam, org.getLinkByRel('self').href);
      }
      return url.toString();
    }
  }

  _entityChanged(entity) {
    super._entityChanged(entity);
    this.ancestorsHref = this.getHref('https://api.binsentry.com/rel/organization-ancestors');
  }

  _onUpdateAncestors(e) {
    const { entity } = e.detail;
    if (!entity) {
      return;
    }
    this.ancestors = entity.entities;
  }
}

window.customElements.define('organization-ancestors-bread-crumbs', OrganizationAncestorsBreadCrumbs);

import { useEffect } from 'react';
import useStoreValues from '../use-store-values.js';
import * as userManager from '../user-manager.js';
import { entityStore } from '../entity-store.js';
import { store } from '../store.js';
import { rememberDeepLink } from '../state-storage.js';
import * as storeActions from '../store-actions.js';
import { publicPaths } from '../public-paths';

/**
 * Listens for changes and performs actions related to authentication/authorization
 */
export function useAuthenticationListeners(config) {
  const { path } = useStoreValues(['path']);

  // Setup the userManager and unauthorized listeners
  useEffect(() => {
    if (config && config.auth) {
      userManager.init(config.auth);
    }
    entityStore.addListener('unauthorized', _handleUnauthorized);

    return () => {
      entityStore.removeListener('unauthorized', _handleUnauthorized);
    };
  }, [config, config?.auth]);

  // Handle forcing users to login if they are viewing a non-public path
  useEffect(() => {
    const currentRoute = (path || '').toLowerCase().slice(1).split('/')[0];
    if (path === undefined || publicPaths.includes(currentRoute)) {
      return;
    }

    userManager.authenticatedUser().then((user) => {
      if (!user) {
        rememberDeepLink();
        store.dispatch(storeActions.login());
      }
    });
  }, [path, config]);
}

const _handleUnauthorized = ({ token }) => {
  rememberDeepLink();

  // if all requests were properly being deferred/dependent on us being in a non-public route, then this shouldn't
  // be necessary, but currently stuff like the nav app makes requests regardless of the route.
  if (token) {
    // if we still get a 401 from an entity after authentication,
    // we don't want to send them into a redirect loop, so we sign them out
    userManager.logout().then(/* nothing */);
  } else {
    store.dispatch(storeActions.login());
  }
};

import posthog from 'posthog-js';
import { useEffect, useRef, useState } from 'react';
import entityStore from '../entity-store.js';
import { getAccountHref } from '../organization-helper.js';
import useSirenEntity from '../use-siren-entity.js';
import useStoreValues from '../use-store-values.js';
import { buildUrl } from '../store-actions.js';

/**
 * Initializes and sends events to PostHog
 */
export function usePostHog(config) {
  const pastUrlRef = useRef();
  const pastCurrentOrgRef = useRef();
  const pastPersonaHref = useRef();
  const { path, queryV2, currentUserHref, primaryOrgHref, currentOrgHref, personas } = useStoreValues([
    'path', 'queryV2', 'currentUserHref', 'primaryOrgHref', 'currentOrgHref', 'personas',
  ]);
  const [posthogInUse, setPosthogInUse] = useState(false);
  const { entity: currentUserEntity } = useSirenEntity({ href: currentUserHref });
  const { entity: primaryOrganizationEntity } = useSirenEntity({ href: primaryOrgHref });
  const { entity: currentOrganization } = useSirenEntity({ href: currentOrgHref });
  const skipPosthog = !path || path.includes('bins/show/level') || import.meta.env.MODE === 'development';
  const url = buildUrl(path, queryV2);

  const primaryOrganizationId = primaryOrganizationEntity?.getLink('self').href;
  const primaryOrganizationName = primaryOrganizationEntity?.properties.name;
  const currentOrganizationId = currentOrganization?.getLink('self').href;
  const currentOrganizationName = currentOrganization?.properties.name;
  const currentAccountId = getAccountHref(currentOrganization);
  const currentUserEmail = currentUserEntity?.properties.email;

  // Initialize PostHog on startup
  useEffect(() => {
    if (posthogInUse) {
      return; // do not disable posthog once it was enabled
    }
    if (!config?.posthog) {
      return; // do not enable posthog if it's not enabled in the config
    }
    if (!currentOrganization) {
      return; // wait for currentOrganization to be available so that we know that the user is authenticated
    }
    if (skipPosthog) {
      return;
    }
    posthog.init(config.posthog.token, {
      api_host: config.posthog.host,
      capture_pageview: false,
      loaded: async () => {
        setPosthogInUse(true);
      },
    });
  }, [skipPosthog, config, currentOrganization, posthogInUse]);

  // Identify the user to PostHog
  useEffect(() => {
    if (!posthogInUse || !currentUserHref || skipPosthog) {
      return;
    }

    const userId = currentUserHref;
    if (!currentUserEmail || !primaryOrganizationId || !primaryOrganizationName) {
      // Just identify the userId, without setting any user attributes
      posthog.identify(userId, { userId });
      return;
    }

    posthog.identify(userId, {
      userId,
      organizationId: primaryOrganizationId,
      email: currentUserEmail,
      organizationName: primaryOrganizationName,
    });
  }, [skipPosthog, currentUserHref, currentUserEmail, primaryOrganizationId, primaryOrganizationName, posthogInUse]);

  // Record page views in PostHog
  useEffect(() => {
    if (!posthogInUse || skipPosthog) {
      return;
    }

    if (!currentOrganizationId || !personas) {
      // Wait for currentOrganization to load and personas to be set before recording events in PostHog.
      // This way we can group events properly
      return;
    }

    let persona;
    if (personas.length === 1) {
      persona = personas[0];
    } else if (personas.length === 0) {
      persona = 'unenrolled';
    } else {
      persona = 'multiple';
    }

    if (pastCurrentOrgRef.current !== currentOrganizationId || pastPersonaHref.current !== persona) {
      pastUrlRef.current = url;
      pastCurrentOrgRef.current = currentOrganizationId;
      pastPersonaHref.current = persona;

      setGroups({ currentOrganizationId, currentOrganizationName, currentAccountId, persona });
      capturePageview();
      return;
    }

    if (pastUrlRef.current !== url) {
      pastUrlRef.current = url;
      capturePageview();
    }
  }, [skipPosthog, url, currentOrganizationId, currentOrganizationName, currentAccountId, personas, posthogInUse]);
}

function setGroups({ currentOrganizationId, currentOrganizationName, currentAccountId, persona }) {
  posthog.group('currentOrganization', currentOrganizationId, {
    name: currentOrganizationName,
    currentOrganizationName: currentOrganizationName,
  });

  posthog.group('persona', persona, {
    name: persona,
    persona,
  });

  // record currentAccount right away so that PostHog starts recording it for page views
  posthog.group('currentAccount', currentAccountId || 'none');

  if (currentAccountId) {
    entityStore.fetchWait(currentAccountId, false).then(({ entity: accountEntity }) => {
      if (accountEntity) {
        posthog.group('currentAccount', currentAccountId, {
          name: accountEntity.properties.name,
          currentAccountName: accountEntity.properties.name,
        });
      }
    });
  }
}

function capturePageview() {
  posthog.capture('$pageview');
}

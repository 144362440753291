import { makeStyles, Paper, Popper, Typography } from '~/lib/bn-material-ui';
import React from 'react';

const useStyles = makeStyles((theme) => {
  return {
    popper: {
      marginLeft: 8,
      zIndex: theme.nav.zIndex + 1,
      boxShadow: theme.shadows[3],
    },
    arrow: {
      position: 'absolute',
      fontSize: 7,
      color: theme.nav.menu.color,
      left: 0,
      marginLeft: '-0.9em',
      marginTop: 8,
      height: '3em',
      width: '1em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.nav.menu.color} transparent transparent`,
      },
    },
    popperPaper: {
      padding: '3px 8px 2px 8px',
      backgroundColor: theme.nav.menu.color,
      color: theme.nav.menu.backgroundColor,
    },
  };
});

function MenuTitlePopper({ anchorEl, title }) {
  const styles = useStyles();
  return (
    <Popper open={!!anchorEl} anchorEl={anchorEl} placement='right' className={styles.popper}>
      <span className={styles.arrow} />
      <Paper className={styles.popperPaper}>
        <Typography>{title}</Typography>
      </Paper>
    </Popper>);
}

export default MenuTitlePopper;

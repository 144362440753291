import { makeStyles } from '~/lib/bn-material-ui';

export const baseStyles = ({ enableSubmenu }) => {
  return makeStyles((theme) => {
    const drawerCollapsedStyles = {
      flexShrink: 0,
      width: theme.nav.menu.width,
    };

    return {
      root: {
        display: 'flex',
      },
      rootHidden: {
        display: 'none',
      },
      backdrop: {
        zIndex: theme.nav.zIndex - 1,
      },
      drawer: {
        zIndex: theme.nav.zIndex,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('xs')]: {
          flexShrink: 0,
          width: 0,
        },
        [theme.breakpoints.up('sm')]: {
          width: theme.nav.menu.width + (enableSubmenu ? theme.nav.submenu.width : 0),
        },
      },
      drawerLinksList: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
      },
      drawerInner: {
        marginTop: theme.nav.appBar.minHeight,
        border: 'none',
        backgroundColor: theme.nav.menu.backgroundColor,
        color: theme.nav.menu.color,
        overflow: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: theme.shadows[3],
        [theme.breakpoints.up('xs')]: {
          flexShrink: 0,
          width: 0,
        },
        [theme.breakpoints.up('sm')]: drawerCollapsedStyles,
      },
      drawerItemText: {
        whiteSpace: 'nowrap',
      },
      shield: {
        width: theme.spacing(3),
        position: 'absolute',
        transition: theme.transitions.create('opacity', {
          easing: 'linear',
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      logo: {
        width: '195px',
        transition: theme.transitions.create('opacity', {
          easing: 'linear',
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('md')]: {
          opacity: 0,
        },
        opacity: 0,
      },
      logoContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      },
      subLinkListItem: {
        paddingLeft: '3rem',
      },
      listItem: {
        marginBottom: '5px',
      },
      listItemUnselected: {
        borderWidth: '0',
        '&:hover': {
          backgroundColor: theme.palette.primary.gray[20],
          boxShadow: theme.shadows[3],
          color: theme.nav.menu.color,
        },
      },
      listItemSelected: {
        backgroundColor: theme.nav.menu.color,
        color: 'var(--white-base)',
        '&:hover': {
          backgroundColor: theme.nav.menu.color,
        },
      },
      listItemIcon: {
        color: 'currentColor',
      },
      searchContainer: {
        position: 'relative',
        margin: '10px 0',
        transition: theme.transitions.create('margin', {
          easing: 'linear',
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('xs')]: {
          margin: '0',
          paddingTop: '20px',
          paddingBottom: '20px',
        },
        [theme.breakpoints.up('sm')]: {
          margin: '0',
        },
      },
      search: {
        position: 'absolute',
        width: `calc(100% - ${theme.spacing(4)}px)`,
        padding: '10px 6px 10px 6px',
        display: 'flex',
        justifyContent: 'center',
        pointerEvents: 'none',
        transition: theme.transitions.create('opacity', {
          easing: 'linear',
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('xs')]: {
          opacity: 1,
        },
        [theme.breakpoints.up('sm')]: {
          opacity: 0,
        },
      },
      searchItemIcon: {
        color: 'currentColor',
        transition: theme.transitions.create('opacity', {
          easing: 'linear',
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    };
  })();
};

import { css } from '~/lib/bn-lit-element';
import { main } from './main';
import { formStyles } from './form-styles';

export const mainStyles = css`
  ${main}
  ::-moz-focus-inner {
    border: 0;
  }

  :root, * {
    --binsentry-purple: #36285b;

    --background: var(--gray-20, #f3f3f3);
    --primary: var(--binsentry-purple, pink);
    --secondary: var(--purple-light, mediumpurple);
    --surface: var(--white-base, white);
    --error: var(--red-main, red);
    --on-primary: var(--white-base, white);
    --on-secondary: var(--black-base, black);
    --on-surface: var(--gray-90, black);
    --on-error: var(--white-base, white);

    --root-margin: 1rem;
  }

  @media print {
    :root, * {
      --background: var(--white-base);
    }
  }

  h1,h2,h3,h4,h5,h6 {
    color: var(--on-surface);
    font-family: var(--heading-font, sans-serif);
  }

  h1 {
    margin: var(--root-margin) var(--root-margin) 0 var(--root-margin);
  }

  h2, h3 { margin: .5em 0; }
  h4, h5, h6 { margin: .3em 0; }

  .subdued {
    color: var(--gray-70, #777);
  }

  table {
    border: 1px solid var(--gray-50);
    border-collapse: collapse;
    width: 100%;
  }

  th {
    background-color: var(--gray-40);
    border: 1px solid var(--gray-50);
    color: var(--gray-90);
    padding: .5em;
    text-align: left;
  }

  td {
    background-color: var(--gray-10);
    border: 1px solid var(--gray-50);
    padding: .5em;
  }

  tr:nth-child(even) td {
    background-color: var(--gray-20);
  }

  tr:hover td {
    background-color: var(--highlight-color);
  }

  table.v2 {
    border: 1px solid var(--gray-60);
    box-shadow: 0 4px 2px -2px var(--gray-40);
  }

  table.v2 th {
    background-color: var(--blue-20);
    border: 0;
    font-weight: normal;
  }

  table.v2 td {
    background-color: var(--white-base);
    border: 0;
  }

  table.v2 .total td {
    background-color: var(--gray-20);
    font-weight: bold;
  }

  table.v2 tr.total {
    border: 1px solid var(--gray-90);
  }

  .unit {
    font-size: .8em;
    color: var(--gray-80);
  }

  .well {
    border: 1px solid var(--gray-50);
    border-radius: 3px;
    background-color: var(--gray-20);
    box-shadow: 0 0 3px var(--gray-50) inset;
  }
  input[type='radio'] {
    position: relative
  }

  input[type='radio']:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #FFFFFF;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid var(--gray-40);
    }
    input[type='radio']:checked:before {
        width: 9px;
        height: 9px;
        border-radius: 15px;
        top: 1px;
        left: 1px;
        position: absolute;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
        z-index: 1;
    }
    input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -2px;
        position: relative;
        background-color: var(--teal-60);
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }

    input[type='radio']:hover:after {
        border-color: var(--teal-60);
    }

    a {
      color: var(--teal-60);
    }

`;

export const sharedStyles = css`
  ${mainStyles}
  ${formStyles}
`;

const template = document.createElement('template');
template.innerHTML = `<dom-module id="shared-styles">
  <template>
    <style>${sharedStyles}</style>
  </template>
</dom-module>`;
document.head.appendChild(template.content);

export default sharedStyles.toString();

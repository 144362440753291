export const publicPaths = [
  'qr-test',
  'nuke',
  'clear',
  'issue-viewer',
  'login-redirect',
  'logout',
  'login',
  'logged-out',
  'confirm',
  'print',
  '/',
];

import React from 'react';
import { MuiThemeProvider, StylesProvider, makeStyles } from '~/lib/bn-material-ui';
import { BnErrorBoundary } from '~/common/bn-error-boundary';
import ResponsiveDrawer from './drawer.component';
import { generateClassName, useStoreValues } from '~/utility/BinSentry-ui-utility';
import navTheme from './nav-styles';
import BnAppBar from './BnAppBar';

const useStyles = makeStyles(() => ({
  navigationContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: '1fr',
    minHeight: '100%',
    overflow: 'hidden',
  },
}));

function Navigation({ ['api-root']: apiRoot, children }) {
  // We want to not request api-root until we have a token / are logged in.
  // This is to allow time for the token to be set in the store, allowing us to get a logged-in version of api-root.
  const { token } = useStoreValues(['token']);
  const styles = useStyles();

  return <BnErrorBoundary locationTag='navigation'>
    <div className={styles.navigationContainer}>
      <MuiThemeProvider theme={navTheme}>
        <StylesProvider generateClassName={generateClassName}>
          <BnAppBar apiRoot={token && apiRoot}/>
          <ResponsiveDrawer apiRoot={token && apiRoot} />
        </StylesProvider>
      </MuiThemeProvider>
      {children}
    </div>
  </BnErrorBoundary>;
}

export default Navigation;

import { Measurements } from '~/utility/BinSentry-ui-utility';
import { LitEntity, html, css, renderIf, nothing } from '../../utility/lit-entity';
import { sharedStyles } from '../../styles/shared-styles';
import { ActiveWorkOrderWatcherMixin } from '../../utility/active-work-order-watcher-mixin';
import '../../common/bn-card';
import '../props/bin-empty-date';
import '../props/bin-name';
import '../props/bin-owner';
import './bin-profile';
import './bin-path-name';
import './bin-summary';

class BinListItem extends ActiveWorkOrderWatcherMixin(LitEntity) {
  static get properties() {
    return {
      referencePoint: { type: Object, attribute: 'reference-point' },
    };
  }

  static get styles() {
    return [sharedStyles, css`
      :host([active-work-order]) bn-card {
        --card-background-color: var(--yellow-lighter);
        --card-border-color: var(--yellow-light);
        --card-shadow-color: var(--yellow-light);
        --card-shadow-hover-color: var(--yellow-main);
      }
      bn-card {
        height: calc(100% - (2 * var(--root-margin)));
      }
      .card-inner {
        display: flex;
        align-items: stretch;
        min-width: 200px;
      }
      :host([active-work-order]) .card-inner {
        color: var(--orange-text);
        --crumb-color: var(--orange-text);
        --crumb-separator-color: var(--yellow-dark);
        --bin-current-report-date-color: var(--yellow-dark);
      }
      .bin-level {
        height: 140px;
        width: 30px;
        min-width: 30px;
      }
      .bin-details {
        flex-grow: 1;
        margin-left: 1em;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
      }
      .bin-details-lower {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-grow: 1;
      }
      .bin-details-lower bn-icon {
        font-size: 2.5em;
        line-height: 0;
      }
      .bin-details-lower .service-notice {
        font-size: .9em;
        font-weight: bold;
      }
      bin-name {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      bin-summary {
        display: block;
      }
      .empty-at {
        line-height: 1em;
      }
      bin-empty-date {
        font-weight: bold;
      }
      .bin-reference-distance {
        margin-top: 0.25rem;
      }
      .org {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .org::after {
        content: " > ";
        font-size: .8em;
        color: var(--gray-70);
      }
      :host([active-work-order]) .org::after {
        color: var(--orange-main);
      }
      .no-device {
        font-size: .9em;
        color: var(--gray-70);
      }
    `];
  }

  render() {
    const { href } = this;
    return html`
      <bn-card
        .href="/bins/show/overview?binId=${encodeURIComponent(this.href)}"
        @click="${this._select}"
        title="${this.activeWorkOrder ? 'Bin Has a Scheduled Work Order' : ''}"
      >
        <div class="card-inner">
          <div class="bin-level">
            <bin-profile .href="${href}" basic></bin-profile>
          </div>
          <div class="bin-details">
            <bin-path-name .href="${href}"></bin-path-name>
            <div class="bin-details-lower">
              <div class="empty-at">${this._renderEmptyAt()}</div>
              ${renderIf(this.activeWorkOrder, html`<bn-icon name="fas fa-exclamation-triangle"></bn-icon>`)}
            </div>
            ${renderIf(this.referencePoint && this.referencePoint.latitude && this.referencePoint.longitude, () => html`
              <div class="bin-reference-distance">${this._getReferencePointDist()}</div>
            `)}
          </div>
        </div>
      </bn-card>
    `;
  }

  _getReferencePointDist() {
    const radlat1 = Math.PI * this.referencePoint.latitude / 180;
    const radlat2 = Math.PI * this.entity.properties.location.latitude / 180;
    const theta = this.referencePoint.longitude - this.entity.properties.location.longitude;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = (dist * 60 * 1.85315962).toFixed(2);
    const measurement = Measurements.get('distance');
    return html`${measurement.html({ distance: dist })} away`;
  }

  _renderEmptyAt() {
    if (!this.entity) {
      return nothing;
    }

    const { hasDevice, monitoringType, type } = this.entity.properties;
    const noDevice = monitoringType !== 'unmonitored' && type !== 'aggregate' && !hasDevice;

    if (noDevice) {
      return html`
        <div class="no-device">
          No device installed
        </div>
      `;
    }

    return html`
      <bin-summary .href="${this.href}" inline precision="1"></bin-summary>
      ${renderIf(!this.activeWorkOrder, () => html`
        <label>Empty by</label>
        <bin-empty-date .href="${this.statsHref}"></bin-empty-date>
      `)}
      ${renderIf(this.activeWorkOrder, () => html`
        <div class="service-notice">Scheduled For Service</div>
      `)}
    `;
  }

  _entityChanged(entity) {
    super._entityChanged(entity);
    this.statsHref = this.getHref('https://api.binsentry.com/rel/bin-stat-latest');
  }

  _select() {
    const detail = { binId: this.href };
    this.dispatchEvent(new CustomEvent('select', { detail, bubbles: true, composed: true }));
  }
}

window.customElements.define('bin-list-item', BinListItem);

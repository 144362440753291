import React from 'react';
import { SvgIcon } from '~/lib/bn-material-ui';

export function BinIcon(props) {
  return (
    <SvgIcon viewBox='0 0 20 20' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.02954 1.04839H7.01726V0.604839C7.01726 0.270795 7.28978 0 7.62596 0H9.96637H12.2926C12.6288 0 12.9013 0.270795 12.9013 0.604839V1.03664C13.9796 1.57443 15.1036 2.21465 15.8241 2.6368C16.5628 3.06965 17 3.85706 17 4.69492V12.0708C17 12.6463 16.7935 13.203 16.4177 13.6408L16.1883 13.9082V19.3504C16.1883 19.7092 15.8249 20 15.3767 20C14.9284 20 14.5651 19.7092 14.5651 19.3504V15.7992L12.4152 18.3039C11.9526 18.8428 11.2756 19.1532 10.5627 19.1532H9.7871C9.10641 19.1532 8.45679 18.8701 7.99577 18.3724L5.43499 15.6083V19.3504C5.43499 19.7092 5.07163 20 4.6234 20C4.17517 20 3.8118 19.7092 3.8118 19.3504V13.8562L3.64345 13.6745C3.22968 13.2278 3 12.643 3 12.0359V4.68835C3 3.85581 3.43182 3.0724 4.16354 2.63796C4.87029 2.21833 5.96983 1.58388 7.02954 1.04839ZM14.0378 5.04034C14.0378 4.59495 13.6744 4.23389 13.2262 4.23389C12.778 4.23389 12.4146 4.59495 12.4146 5.04034V11.3307C12.4146 11.776 12.778 12.1371 13.2262 12.1371C13.6744 12.1371 14.0378 11.776 14.0378 11.3307V5.04034Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}

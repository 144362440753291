import React, { useEffect, useState, useRef } from 'react';
import { CircularProgress, makeStyles } from '~/lib/bn-material-ui';

const LOADING_SPINNER_DELAY_MS = 750;

const useStyles = makeStyles(() => {
  return {
    loadingElement: {
      display: 'none',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
});

export function BnDynamicComponent({ getComponent, componentProperties }) {
  const mounted = useRef(false);
  const getComponentRef = useRef();
  getComponentRef.current = getComponent;

  const [componentImport, setComponentImport] = useState();
  const loadingElementRef = useRef();
  const styles = useStyles();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setComponentImport();

    const loadComponent = async () => {
      const currentGetComponent = getComponentRef.current;
      const importResult = await getComponentRef.current();
      // We need to be sure that when the loading completes, our props have not changed, ie
      // we are trying to load a different component now
      if (mounted.current && currentGetComponent === getComponentRef.current) {
        setComponentImport(() => importResult);
      }
    };
    loadComponent();

    setTimeout(() => {
      if (mounted.current && loadingElementRef.current) {
        loadingElementRef.current.style.display = 'flex';
      }
    }, LOADING_SPINNER_DELAY_MS);
  }, [getComponent]);

  if (componentImport) {
    const Component = componentImport;
    return <Component {...componentProperties} />;
  }

  return (
    <div className={styles.loadingElement} ref={loadingElementRef}>
      <CircularProgress size='3em' />
    </div>
  );
}

import { LitElement, html } from '~/lib/bn-lit-element';
import { LitColours } from './colours.js';
import { fonts } from './fonts.js';

class RootStyles extends LitElement {
  static get properties() {
    return {
      config: { type: Object },
    };
  }

  createRenderRoot() {
    // Causes lit-element to not create a shadowDOM & inject html into the host DOM
    // Which allows all elements etc to use styles defined by us
    return this;
  }

  // The FA url must exactly match the url in @BinSentry-ui/app/js/common/bn-icon.js
  // TODO: Export FA url from this MFE so it can be consumed/used by bn-icon instead!
  render() {
    return html`
      <style>
        ${LitColours.toString()}
      </style>
      <style>
        ${fonts.toString()}
      </style>
      <link
        rel="stylesheet"
        href="https://pro.fontawesome.com/releases/v5.15.1/css/all.css"
        integrity="${this.config.font_awesome_key}"
        crossorigin="anonymous"
      />
    `;
  }
}

if (!window.customElements.get('root-styles')) {
  window.customElements.define('root-styles', RootStyles);
}

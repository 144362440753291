import { useEffect, useState } from 'react';
import SirenParse from 'siren-parser';
import useSirenEntity from './use-siren-entity.js';
import { entityStore } from './entity-store.js';

/**
 * Loads the full siren collection, ignoring pagination.  This should only be used when the size of the collection
 * is known to be somewhat small.
 * @param href The collection endpoint
 * @param itemRel The rel of the collection sub-entities to return
 * @param onlyLoadFirstPage If true, the collection only contains the first page of items
 * @return {{collection: [], loading: boolean}} The collection of siren entities loaded so far
 */
export default function useFullSirenCollection({ href, itemRel = 'item', onlyLoadFirstPage = true }) {
  const [collection, setCollection] = useState([]);
  const [fullCollectionLoaded, setFullCollectionLoaded] = useState(!href);
  const { entity: firstPageEntity, loading: loadingFirstPage } = useSirenEntity({ href });

  useEffect(() => {
    let cancelled = false;
    (async () => {
      let localCollection = firstPageEntity?.getSubEntities(itemRel) || [];
      setFullCollectionLoaded(false);
      setCollection(localCollection);

      if (firstPageEntity && !loadingFirstPage && !onlyLoadFirstPage) {
        let activePageEntity = firstPageEntity;
        while (activePageEntity.getLink('next')) {
          const nextHref = activePageEntity.getLink('next').href;
          const result = await entityStore.fetchWait(nextHref, true);
          if (cancelled) {
            return;
          }
          activePageEntity = SirenParse(result.entity);
          localCollection = [...localCollection, ...activePageEntity.getSubEntities(itemRel)];
          setCollection(localCollection);
        }
        setFullCollectionLoaded(true);
      }
    })();
    return () => {
      cancelled = true;
    };
  }, [firstPageEntity, loadingFirstPage, onlyLoadFirstPage, itemRel]);

  return {
    collection,
    fullCollectionLoaded: !loadingFirstPage && fullCollectionLoaded,
    loading: onlyLoadFirstPage ? loadingFirstPage : loadingFirstPage || !fullCollectionLoaded,
    totalCount: firstPageEntity?.properties?.totalCount,
  };
}

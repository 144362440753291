const defaultThreshold = {
  percent: undefined,
  name: 'default',
  colour: 'var(--purple-main)',
};

// thresholds for bin levels
// specify colour to use for bin levels up to the threshold.percent
const thresholds = [
  {
    percent: 30,
    name: 'low',
    colour: 'var(--red-50)',
    darkColour: 'var(--red-60)',
    darkerColour: 'var(--red-70)',
  },
  {
    percent: 50,
    name: 'medium',
    colour: 'var(--tangerine-40)',
    darkColour: 'var(--tangerine-50)',
    darkerColour: 'var(--tangerine-60)',
  },
  {
    percent: 100,
    name: 'high',
    colour: 'var(--green-40)',
    darkColour: 'var(--green-50)',
    darkerColour: 'var(--green-60)',
  },
];

export const LevelColours = {
  getThreshold(percent) {
    if (!thresholds) {
      return defaultThreshold;
    }
    for (const threshold of thresholds) {
      if (percent <= threshold.percent) {
        return threshold;
      }
    }
    return thresholds[thresholds.length - 1];
  },

  getThresholdByName(name) {
    return thresholds.find(threshold => threshold.name === name);
  },
};

export const LevelColoursMixin = (superClass) => class extends superClass {
  constructor() {
    super();
    this.thresholds = [...thresholds];
  }

  getThreshold(percent) {
    return LevelColours.getThreshold(percent);
  }

  getLevelColour(percent, variant = 'colour') {
    return LevelColours.getThreshold(percent)[variant];
  }
};

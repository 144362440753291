import { useEffect } from 'react';
import { useStore } from '../BinSentry-ui-utility.js';

/**
 * Initializes localizejs
 */
export function useLocalize() {
  const { userFeatures } = useStore();
  const enableLocalization = userFeatures?.enableLocalization === true;

  useEffect(() => {
    if (enableLocalization) {
      window.Localize?.initialize({
        key: 'ss45qhK1pSf6R',
        rememberLanguage: true,
      });
    }
  }, [enableLocalization]);
}

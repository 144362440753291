import { Measurements, getBinLevelMeasurementOverrides } from '~/utility/BinSentry-ui-utility';
import { LitEntity, html, css } from '../../utility/lit-entity';
import { sharedStyles } from '../../styles/shared-styles';
import '../../common/bn-placeholder';

class BinLevelMetric extends LitEntity {
  constructor() {
    super();
    this.precision = 2;
  }

  static get properties() {
    return {
      volume: { type: Number, attribute: false },
      weight: { type: Number, attribute: false },
      measurement: { type: Object, attribute: false },
      precision: { type: Number },
    };
  }

  static get styles() {
    return sharedStyles;
  }

  render() {
    if (this.loading || !this.measurement) {
      return html`<bn-placeholder></bn-placeholder>`;
    }

    return html`
      ${this._renderValue()}
      <bin-latest-level .href="${this.href}" @levelUpdate="${this._updateLevel}"></bin-latest-level>
    `;
  }

  _updateLevel({ detail: { volume } }) {
    this.volume = volume;
  }

  _renderValue() {
    const bulkDensity = this.getProperty('bulkDensity');
    const volumes = this.getProperty('volumes');
    const totalVolume = volumes && volumes.fullTotal;
    const { volume } = this;
    const value = this.measurement.convert({ volume, bulkDensity, totalVolume });

    if (isNaN(value)) {
      return html`-`;
    }

    return html`${value.toFixed(this.precision)} <span class="unit">${this.measurement.unit}</span>`;
  }

  async _entityChanged(entity) {
    super._entityChanged(entity);
    this.latestLevelHref = this.getHref('https://api.binsentry.com/rel/bin-level-latest');
    this.measurement = Measurements.get('binLevel', await getBinLevelMeasurementOverrides(this.entity));
  }
}

window.customElements.define('bin-level-metric', BinLevelMetric);
